import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import useMoovitIntegration from '@hooks/useMoovitIntegration'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentLabel from '@pages/Checkout/Payment/Label'

export const useNativeGooglePay = (): AdyenTypes.Method => {
  const { getGPayAvailability, getGPayTokenData } = useMoovitIntegration()
  const [status, setStatus] = useState(PaymentMethodStatus.Pending)
  const [error, setError] = useState<Error | null>(null)

  const getOption = useCallback(
    () => ({
      value: 'native_google_pay',
      label: <PaymentLabel type="googlePay" />,
    }),
    [],
  )

  useEffect(() => {
    getGPayAvailability()
      .then(isAvailable => setStatus(isAvailable ? PaymentMethodStatus.Ready : PaymentMethodStatus.Rejected))
      .catch(() => setStatus(PaymentMethodStatus.Rejected))
  }, [getGPayAvailability])

  return useMemo(
    () => ({
      status: status,
      getOption,
      isSubmitting: false,
      error,
      on: {
        submitForm: async (data: CheckoutFormData) => {
          /* istanbul ignore next */
          if (!data.price) return

          const token = await getGPayTokenData(data.price)

          if (!token.paymentData) {
            const error = new Error('Transaction hasn’t been completed')
            setError(error)
            throw error
          }

          return {
            ...data,
            paymentMethodData: {
              googlePayCardNetwork: token.paymentData.paymentMethodData.info.cardNetwork,
              googlePayToken: token.paymentData.paymentMethodData.tokenizationData.token,
              type: 'googlepay',
            },
            paymentMethod: 'google_pay',
          }
        },
      },
    }),
    [status, getOption, error, getGPayTokenData],
  )
}
