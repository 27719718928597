import { Field, useFormikContext } from 'formik'
import React, { useMemo } from 'react'

import CountryField from '@components/CountryField'
import StateField from '@components/StateField'
import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import CompanyInvoiceFields from '@pages/Checkout/Form/Invoice/Form/Company'
import IndividualInvoiceFields from '@pages/Checkout/Form/Invoice/Form/Individual'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Tabs } from '@ui'
import InputField from '@ui/Input/Field'

import '@pages/Checkout/Form/Invoice/Form/index.scss'

const InvoiceForm = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CheckoutFormData>()
  const { t } = useTranslation()

  const { invoice } = values

  const options = useMemo(
    () => [
      { value: 'company', label: t('checkout.invoice.company') },
      { value: 'individual', label: t('checkout.invoice.individual') },
    ],
    [t],
  )

  return (
    <div className="row wrap invoice-form gap-3">
      <div className="cell-12">
        <Tabs
          variant="outlined"
          options={options}
          value={invoice?.type ?? options[0].value}
          onClick={type => {
            if (values.invoice?.type === type) return

            setFieldValue('invoice', null, false)
            setFieldValue('invoice.type', type, false)
            setFieldTouched('invoice', false, false)
          }}
        />
      </div>
      {invoice?.type === 'company' && <CompanyInvoiceFields />}
      {invoice?.type === 'individual' && <IndividualInvoiceFields />}
      <div className="cell-12 cell-md-6 cell-lg-6">
        <CountryField name="invoice.country" label={t('checkout.invoice.country')} required validate={required} />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <StateField
          validate={required}
          countryCode={values.invoice?.country}
          name="invoice.state"
          label={t('checkout.invoice.state')}
        />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.city"
          label={t('checkout.invoice.city')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-md-3 cell-lg-3">
        <Field
          name="invoice.zipCode"
          label={t('checkout.invoice.zipCode')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-md-3 cell-lg-3" />
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.address"
          label={t('checkout.invoice.address')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-6 hidden block-md block-lg" />
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field name="contact.email" label={t('checkout.invoice.certifiedEmail')} component={InputField} disabled />
      </div>
    </div>
  )
}

export default InvoiceForm
