const vector = {
  swap: (vector: Vector): Vector => ({ x: vector.y, y: vector.x }),
  toKey: (vector: Vector): string => `${vector.x},${vector.y}`,
  step: {
    straight: (vector: Vector): Vector => ({ x: vector.x, y: vector.y - 1 }),
    right: (vector: Vector): Vector => ({ x: vector.x + 1, y: vector.y }),
    back: (vector: Vector): Vector => ({ x: vector.x, y: vector.y + 1 }),
    left: (vector: Vector): Vector => ({ x: vector.x - 1, y: vector.y }),
  },
}

export default vector
