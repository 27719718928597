import { useQuery } from '@tanstack/react-query'

import portalApi from '@api/portal'
import { useAppContext } from '@context/app'

export const usePaymentTerminals = (provider: PaymentProvider) => {
  const { retailerPartnerNumber, marketingCarrierCode } = useAppContext()

  return useQuery({
    queryKey: ['portalSettings', retailerPartnerNumber, marketingCarrierCode, provider],
    enabled: retailerPartnerNumber > 0 && marketingCarrierCode != null,
    queryFn: () =>
      portalApi.paymentTerminals.load({
        retailerPartnerNumber,
        marketingCarrierCode: marketingCarrierCode!,
        paymentProvider: provider,
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  })
}
