import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import seatUtils from '@lib/seatSelection'
import Label from '@pages/Checkout/Extras/Seats/Label'
import { Seats } from '@pages/Checkout/hooks/useInitialFormValues'

interface InfoProps {
  seats: Seats | null
}

const displaySeats = (seats: Seat.Selected[]): string => seats.flatMap(item => item.label).join(', ')

const Info = ({ seats }: InfoProps): ReactElement => {
  const { t } = useTranslation()

  const outboundSeats = seatUtils.flatten(seats?.outbound)
  const inboundSeats = seatUtils.flatten(seats?.inbound)

  const outboundLabel = seats?.outbound && displaySeats(outboundSeats)
  const inboundLabel = seats?.inbound && displaySeats(inboundSeats)

  return (
    <span className="extras__seats-description row-lg column">
      {outboundLabel && <Label type="outbound" label={outboundLabel} />}
      {inboundLabel && <Label type="inbound" label={inboundLabel} />}
      <span>{!outboundLabel && !inboundLabel && t('extras.seats.selection_description')}</span>
    </span>
  )
}

export default Info
