import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import AncillaryContext from '@pages/Checkout/Extras/Ancillary/Context'
import Card from '@pages/Checkout/Extras/Card'
import Description from '@pages/Checkout/Extras/Card/Description'
import useAncillaries from '@pages/Checkout/hooks/useAncillaries'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

interface DefaultProps {
  name: KnownAncillary
  children?: ReactNode
  controls?: ReactNode
  classnames?: string
  pricePerPassenger?: boolean
}

const InlineAncillary = ({
  name,
  children,
  controls,
  classnames,
  pricePerPassenger,
}: DefaultProps): ReactElement | null => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [{ reservation, ancillaries }] = useSettings()
  const { values, initialValues, setFieldValue } = useFormikContext<CheckoutFormData>()
  const [{ inbound, outbound }] = useCheckout()
  const { list } = useAncillaries({ name, outbound, inbound })
  const initialValue = useMemo(() => initialValues.ancillaries[name], [initialValues.ancillaries, name])
  const ancillary = useMemo(() => list[0], [list])

  useEffect(() => {
    !reservation.enabled && !ancillaries.preselected.length && setFieldValue(`ancillaries.${name}`, initialValue)
  }, [values.passengers.length, setFieldValue, name, initialValue, reservation, ancillaries])

  // istanbul ignore next
  if (!ancillary) return null

  const descriptionModal = ancillary.description && (
    <Description title={ancillary.name} description={ancillary.description} />
  )
  const displayDescription = t(`extras.ancillary.${ancillary.category.toLowerCase()}.description`, { defaultValue: '' })
  const description = isMobile ? descriptionModal : displayDescription

  return (
    <AncillaryContext.Provider value={{ ancillary }}>
      <div className={cn(bem('extras', 'section'), classnames)}>
        <Card
          icon={ancillaryUtils.getIcon(name)}
          title={t(`extras.ancillary.${name.toLowerCase()}.title`)}
          price={ancillary.price}
          description={description}
          pricePerPassenger={pricePerPassenger}
          controls={
            <div className="row gap-3">
              {!isMobile && descriptionModal}
              {controls}
            </div>
          }
        />
        {children}
      </div>
    </AncillaryContext.Provider>
  )
}

export default InlineAncillary
