import React, { ReactNode } from 'react'

import bem from '@lib/bem'

import '@ui/ButtonGroup/index.scss'

interface Props {
  children: ReactNode
  flat?: boolean
}

const ButtonGroup = (props: Props) => {
  const { children, flat } = props

  return <div className={bem('ui-button-group', { flat })}>{children}</div>
}

export default ButtonGroup
