import React, { ReactElement } from 'react'

import IconSeat, { IconSeatCode, IconSeatProps, iconsMap } from '@components/Seats/Item/Icon'
import PassengerSeat, { PassengerSeatProps } from '@components/Seats/Item/Passenger'
import ServiceSeat, { LabeledSeatProps, serviceLabels, ServiceSeatCode } from '@components/Seats/Item/Service'
import TableSeat, { TableSeatProps } from '@components/Seats/Item/Table'

import '@components/Seats/Item/index.scss'

interface PassengerSeatWithCode extends PassengerSeatProps {
  code: string
}

interface TableSeatWithCode extends TableSeatProps {
  code: 'TABLE'
}

type SeatProps = LabeledSeatProps | PassengerSeatWithCode | TableSeatWithCode | IconSeatProps

export const isServiceSeat = (props: SeatProps): props is LabeledSeatProps =>
  !!serviceLabels[props.code as ServiceSeatCode]

export const isIconSeat = (props: SeatProps): props is IconSeatProps => !!iconsMap[props.code as IconSeatCode]

const isTableSeat = (props: SeatProps): props is TableSeatWithCode => ['TABLE'].includes(props.code)

const SeatComponent = (props: SeatProps): ReactElement | null => {
  if (isTableSeat(props)) {
    return <TableSeat {...props} />
  }

  if (isServiceSeat(props)) {
    return <ServiceSeat {...props} />
  }

  if (isIconSeat(props)) {
    return <IconSeat {...props} />
  }

  return <PassengerSeat {...props} />
}

export default SeatComponent
