import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import Loader from '@components/Loader'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { VGS } from '@lib/vgs'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import BillingAddress from '@pages/Checkout/Payment/BillingAddress'
import CVCField from '@pages/Checkout/Payment/CreditCardForm/VGS/CVCField'
import ExpDateField from '@pages/Checkout/Payment/CreditCardForm/VGS/ExpDateField'
import HolderField from '@pages/Checkout/Payment/CreditCardForm/VGS/HolderField'
import NumberField from '@pages/Checkout/Payment/CreditCardForm/VGS/NumberField'
import { useSettings } from '@queries/settings'

import '@pages/Checkout/Payment/CreditCardForm/VGS/index.scss'

interface VGSCardProps {
  vgsForm?: VGS.Form | null
}
const VGSCard = ({ vgsForm }: VGSCardProps): ReactElement => {
  const { t } = useTranslation()
  const [{ paymentProvidersMethods, billingAddress }] = useSettings()
  const {
    touched: { paymentMethodData: formTouched },
  } = useFormikContext<CheckoutFormData>()

  const useCVC = paymentProvidersMethods.creditCard?.options?.cvc

  /* istanbul ignore if */
  if (!vgsForm) return <Loader loading />

  return (
    <div className="row">
      <div className="cell-7 cell-sm-12">
        <div className="column gap-3">
          <div className={cn('cell-12', bem('vgs'))}>
            <NumberField
              form={vgsForm}
              name="cardNumber"
              placeholder={t('checkout.card.number')}
              touched={formTouched}
            />
            <ExpDateField
              form={vgsForm}
              name="cardExpiry"
              placeholder={t('checkout.card.expireAt')}
              touched={formTouched}
            />
            {useCVC && (
              <CVCField form={vgsForm} name="cvv" placeholder={t('checkout.card.cvc')} touched={formTouched} />
            )}
            <HolderField
              form={vgsForm}
              name={'cardHolderName'}
              placeholder={t('checkout.card.holder')}
              touched={formTouched}
            />
          </div>
          {billingAddress.enabled && (
            <div className="cell-12">
              <BillingAddress />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VGSCard
