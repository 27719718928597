import { createContext } from 'react'

interface TrainSeatSelectionContext {
  opened: boolean
  scheme: Seat.Train.Data['scheme']
  connection: Connection
  fareClass: string
  segmentsCount: number
  currentSegment: number
  fareOptions: Option<string>[]
  setFareClass?: (value: string) => void
  carIndex: number
  setCarIndex: (value: number) => void
  disableFare?: boolean
  reservedSeatsCount: number
  onSelect: (seat: Seat.Selected) => void
  onSubmit: (passengerCard?: DiscountCard.Item | null) => void
  selectedSeats: Seat.Selected[]
  onNavigateBack?: () => void
  maxSegmentCount: number
  layout: Seat.Train.Layout | null
  connectionSegment: Segment
  schemeSegment: Seat.Train.Segment
  onClear?: () => void
  allowSkip?: boolean
}

export const TrainSeatSelectionContext = createContext<TrainSeatSelectionContext>(null as any)
