import cn from 'classnames'
import React, { ReactNode, useState } from 'react'

import bem from '@lib/bem'
import { Accordion, Tabs } from '@ui'

import '@components/FAQTabs/index.scss'

interface Topic {
  title: string
  content: ReactNode
}

interface TabContent {
  title: string
  items: Topic[]
}

export interface FAQTabsProps {
  tabs: TabContent[]
  title: string
  description: string
}

const FAQTabs = (props: FAQTabsProps) => {
  const { tabs, title, description } = props
  const [activeTab, setActiveTab] = useState(tabs[0].title)
  const tabsOptions = tabs.map(tab => ({ value: tab.title, label: tab.title }))
  const activeTabData = tabs.find(tab => tab.title === activeTab)

  return (
    <div className="column gap-3 faq-tabs">
      <h3 className="mb-2">{title}</h3>
      <div className={cn('body-14  mb-1', bem('faq-tabs', 'description'))}>{description}</div>
      <div className={cn('cell', bem('faq-tabs', 'content'))}>
        <div className="column">
          <div className={bem('faq-tabs', 'navigation')}>
            <Tabs options={tabsOptions} value={activeTab} onClick={setActiveTab} />
          </div>
          {activeTabData?.items.map(item => (
            <Accordion title={<div className="body-14 bolder">{item.title}</div>} key={item.title}>
              <div className="body-14 pb-2">{item.content}</div>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQTabs
