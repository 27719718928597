import { useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import { FinishPaymentParams } from '@hooks/useBookingFlow'
import { OptionType, useBookingPayment } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentOptionSkeleton from '@pages/Checkout/Payment/OptionSkeleton'
import { useCheckout } from '@stores/checkout'
import { Skeleton } from '@ui'
import ExpandableRadioGroup from '@ui/RadioGroup/Expandable'

interface PaymentOptionsFieldProps {
  confirmationOnly?: boolean
  finishPayment: (params: FinishPaymentParams) => void
}

const PaymentOptionsField = ({ confirmationOnly, finishPayment }: PaymentOptionsFieldProps): ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [, setCheckout] = useCheckout()
  const {
    setFieldValue,
    setFieldTouched,
    initialValues,
    setSubmitting,
    values: { paymentMethod },
  } = useFormikContext<CheckoutFormData>()
  const { available, selected } = useBookingPayment({
    methodType: paymentMethod,
    confirmationOnly,
    finishPayment,
  })

  const paymentOptions = useMemo(
    () =>
      available
        .reduce<(OptionType | OptionType[])[]>((options, { status, getOption }) => {
          if (status !== PaymentMethodStatus.Ready) return options
          const option = getOption()

          return option == null ? /* istanbul ignore next */ options : [...options, option]
        }, [])
        .flat(),
    [available],
  )
  const isMethodsLoading = useMemo(
    () => available.some(({ status }) => status === PaymentMethodStatus.Pending),
    [available],
  )

  const onMethodChange = useCallback(
    (method: PaymentMethodType, shouldValidate?: boolean): void => {
      setFieldValue('paymentMethod', method, shouldValidate)
      setFieldValue('paymentMethodData', initialValues.paymentMethodData, shouldValidate)
      setFieldValue('platform', initialValues.platform, shouldValidate)
      setFieldTouched('paymentMethodData', false, shouldValidate)
      setSubmitting(false)
    },
    [initialValues, setFieldTouched, setFieldValue, setSubmitting],
  )

  useEffect(() => {
    if (!paymentMethod && !isMethodsLoading) {
      onMethodChange(paymentOptions[0]?.value, false)
      setIsInitialized(true)
    }
  }, [isMethodsLoading, onMethodChange, paymentMethod, paymentOptions])

  useEffect(() => {
    setCheckout({ paymentInstance: selected })
    setFieldValue('paymentProvider', selected?.provider)
  }, [selected, setCheckout, setFieldValue])

  return (
    <div className="payment-methods">
      <Skeleton.List Skeleton={PaymentOptionSkeleton} amount={3} loading={!isInitialized} direction="column" divider>
        <ExpandableRadioGroup value={paymentMethod} options={paymentOptions} onChange={onMethodChange} divider />
      </Skeleton.List>
    </div>
  )
}

export default PaymentOptionsField
