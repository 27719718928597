import React, { useMemo } from 'react'

import PayWithCalculator from '@pages/Checkout/hooks/Payment/Delayed/components/PayWithCalculator'
import { buildDelayedMethod } from '@pages/Checkout/hooks/Payment/Delayed/helpers/build'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { useSettings } from '@queries/settings'

const delayedProps = buildDelayedMethod({ type: 'cash' })

const useCash = (): PaymentMethod => {
  const [{ changeCalculator }] = useSettings()

  const getPayButton = useMemo(
    () => (changeCalculator.enabled ? () => <PayWithCalculator /> : undefined),
    [changeCalculator.enabled],
  )

  return useMemo(() => ({ ...delayedProps, getPayButton }), [getPayButton])
}

export default useCash
