import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import fare from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import ContactDetails from '@pages/Checkout/express/ContactDetails'
import Message from '@pages/Checkout/express/Message'
import Route from '@pages/Checkout/express/Route'
import TicketDetails from '@pages/Checkout/express/TicketDetails'
import AncillaryFieldSet from '@pages/Checkout/Extras/Ancillary/FieldSet'
import FareClassesSelection from '@pages/Checkout/FareClassesSelection'
import useFareClass from '@pages/Checkout/hooks/useFareClass'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

const ExpressFields = (): ReactElement => {
  const { t } = useTranslation()
  const [{ outbound, inbound }] = useCheckout()
  const [{ hideTicketDetails }] = useParams()
  const { values } = useFormikContext<CheckoutFormData>()
  const { showFareClassSelection, fareClassList } = useFareClass()
  const { showAncillaries } = useShowExtras({ seatsError: null, values, outbound, inbound })

  const isReturn = fare.getFareByCode(values.fareClass, outbound)?.fareClass.journeyType === 'open_return'
  const isLoading = !outbound

  return (
    <>
      <div className="cell">
        <Skeleton.List Skeleton={() => <Skeleton.Item height={100} />} loading={isLoading} amount={1}>
          <Route connection={outbound} isReturn={isReturn} />
        </Skeleton.List>
      </div>
      {outbound && (
        <div className="cell">
          <div className={bem('checkout', 'message')}>
            <Message isReturn={isReturn} outbound={outbound} />
          </div>
        </div>
      )}
      {!hideTicketDetails && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <TicketDetails />
          </div>
        </div>
      )}
      <div className="cell">
        <div className={bem('checkout', 'section')}>
          <ContactDetails />
        </div>
      </div>
      {showFareClassSelection && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <FareClassesSelection fareClassList={fareClassList} isLoading={!outbound} />
          </div>
        </div>
      )}
      {showAncillaries && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <div className="extras cell-12 column">
              <h3>{t('extras.title')}</h3>
              <AncillaryFieldSet
                isLoading={isLoading}
                formData={values}
                seatsError={null}
                outbound={outbound}
                inbound={inbound}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ExpressFields
