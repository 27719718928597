import { useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import bem from '@lib/bem'
import { useDiscountCardsLoader } from '@loaders/discountCards'
import ContactDetails from '@pages/Checkout/ContactDetails'
import Discounts from '@pages/Checkout/Discounts'
import Extras from '@pages/Checkout/Extras'
import FareClassesSelection from '@pages/Checkout/FareClassesSelection'
import SplitFareClasses from '@pages/Checkout/FareClassesSelection/SplitFareClasses'
import useFareClass from '@pages/Checkout/hooks/useFareClass'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useShowExtras } from '@pages/Checkout/hooks/useShowExtras'
import PassengerDetails from '@pages/Checkout/PassengerDetails'
import useMediaQueries from '@queries/media'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'

const CheckoutFields = (): ReactElement => {
  const [{ outbound, inbound }] = useCheckout()
  const [{ ancillaries, discountCodes, media, email, phone }] = useSettings()
  const [{ currency, locale, retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const [seatsError, setSeatsError] = useState<ErrorCode | null>(null)
  const { showFareClassSelection, showFareClassSplit, fareClassList } = useFareClass()

  const isDiscountsEnabled =
    discountCodes.enabled && (discountCodes.displayOn === 'checkout' || discountCodes.displayOn === 'everywhere')
  const isMediaEnabled = media.enabled && (media.displayOn === 'checkout' || media.displayOn === 'everywhere')

  const { isLoading, data: cards } = useDiscountCardsLoader(
    {
      locale,
      retailerPartnerNumber,
      types: ['discount_card', 'loyalty'],
      ...(marketingCarrierCode && { marketingCarrierCodes: [marketingCarrierCode] }),
    },
    { enabled: isDiscountsEnabled },
  )

  const [mediaData] = useMediaQueries({
    connections: [outbound, inbound].filter(Boolean) as Connection[],
    enabled: isMediaEnabled,
  })

  const { showExtras } = useShowExtras({ seatsError, values, outbound, inbound })
  const bookingCards = useMemo(() => cards?.filter(c => c.context === 'booking'), [cards])
  const showDiscounts = isLoading || (isDiscountsEnabled && bookingCards && bookingCards.length > 0)
  const showContact = email.enabled || phone.enabled

  useEffect(() => {
    setFieldValue('vacancies', [])
  }, [currency, setFieldValue])

  return (
    <>
      {showFareClassSplit && (
        <div className="cell">
          <SplitFareClasses media={mediaData} />
        </div>
      )}
      <div className="cell">
        <div className={bem('checkout', 'section')}>
          <PassengerDetails />
        </div>
      </div>
      {showContact && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <ContactDetails />
          </div>
        </div>
      )}
      {showFareClassSelection && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <FareClassesSelection fareClassList={fareClassList} isLoading={!outbound} media={mediaData} />
          </div>
        </div>
      )}
      {showDiscounts && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <Discounts discountCards={bookingCards} isLoading={isLoading || values.isVacancyLoading} />
          </div>
        </div>
      )}
      {showExtras && (
        <div className="cell">
          <div className={bem('checkout', 'section')}>
            <Extras
              isConnectionsLoading={!outbound}
              onSeatsError={setSeatsError}
              seatsError={seatsError}
              outbound={outbound}
              inbound={inbound}
              preselectedAncillaries={ancillaries.preselected}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CheckoutFields
