import { Field, FormikErrors, useFormikContext } from 'formik'
import React, { ReactElement, useState } from 'react'

import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'
import { Input } from '@ui'

interface JjkDiscountInputProps {
  name: string
  checkType: (type: string) => boolean
}

const KavanaghDiscount = ({ name, checkType }: JjkDiscountInputProps): ReactElement | null => {
  const { t } = useTranslation()
  const { setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext<CheckoutFormData>()
  const [selectedCardCode, setSelectedCardCode] = useState<string>('')
  const { error, touched, value } = getFieldMeta<PassengerData>(name)

  const handleCodeBlur = () => {
    setFieldValue(`${name}.cards`, [{ name: 'YAC', code: selectedCardCode }])
    setFieldTouched(`${name}.cards`, true)
  }

  if (!checkType(value.type)) return null

  return (
    <Field name={`${name}.cards`} validate={() => required(selectedCardCode)}>
      {() => (
        <Input
          label={t('passengers.discounts.labelYAC')}
          value={selectedCardCode}
          onChange={setSelectedCardCode}
          onBlur={handleCodeBlur}
          errorMessage={touched ? (error as FormikErrors<{ cards?: string }>)?.['cards'] : undefined}
          required
        />
      )}
    </Field>
  )
}

export default KavanaghDiscount
