/* istanbul ignore file */
import makeApi from '@lib/api'

export type SeatsResponse = Seat.Data[]

export interface SeatsRequest {
  marketingCarrierCode: string
  arrivalStation?: string
  departureStation?: string
  departureTime: string | null
  arrivalTime: string | null
  locale: string
  currency: string
  extraFields?: string
  fareClass?: string | null
}

const findMeta = (array: any, id: string, limitId: string) => {
  const limitations = array.included.find((item: any) => item.id === id)?.relationships?.limitations?.data

  return limitations.find((item: any) => item.id === limitId)?.meta
}

const findLimitations = <T extends { id: string }>(array: T[] | undefined, id: string, rawResponse: any) =>
  array?.map(limit => ({ ...limit, meta: findMeta(rawResponse, id, limit.id) })) ?? /* istanbul ignore next */ []

const transformResponse = (response: SeatsResponse, rawResponse: any, requestParams: SeatsRequest): SeatsResponse => {
  if (rawResponse?.jsonapi?.version == null) return rawResponse
  if (!requestParams.extraFields) return response

  return response.map(item => ({
    ...item,
    cars: item.cars.map(car => ({
      ...car,
      limitations: findLimitations(car.limitations, car.id, rawResponse),
      seats: car.seats.map(seat => ({ ...seat, limitations: findLimitations(seat.limitations, seat.id, rawResponse) })),
    })),
  }))
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SeatsAvailabilityApi {
  export interface Passenger {
    type: string
    pax: number
  }

  export interface Request extends Omit<SeatsRequest, 'locale' | 'extraFields'> {
    retailerPartnerNumber: number
    passengers: Passenger[]
  }

  export interface Item extends JsonApi.ItemConfig {
    type: 'seat_availabilities'
    attributes: { element_id: string; code: string; status: 'available' | 'unavailable' | 'preselected'; price: number }
    relationships: { layout: Layout; segment: Segment; coach: Coach }
  }

  export interface Coach extends JsonApi.ItemConfig {
    type: 'coaches'
    attributes: { label: string; order_position: number }
    relationships: { vehicle: Vehicle; layouts: Layout[] }
  }

  export interface Layout extends JsonApi.ItemConfig {
    type: 'layouts'
    attributes: { code: string; level: number; size: { length: number; width: number; height: number } }
    relationships: { coach: Coach }
  }

  export interface Vehicle extends JsonApi.ItemConfig {
    type: 'vehicles'
    attributes: { vehicle_type: string }
    relationships: { coaches: Coach[] }
  }

  export interface Segment extends JsonApi.ItemConfig {
    type: 'segments'
    attributes: { departure_time: string; arrival_time: string; index: number }
    relationships: { arrival_station: Station; departure_station: Station }
  }

  export interface Station extends JsonApi.ItemConfig {
    type: 'stations'
    attributes: { code: string }
  }

  export interface PreselectedAvailability extends JsonApi.ItemConfig {
    type: 'preselected_seat_availabilities'
    attributes: {}
    relationships: { seat_availability: Item }
  }

  export type Response = JsonApi.Response<
    JsonApi.Item<Item>,
    | JsonApi.Item<Layout>
    | JsonApi.Item<Segment>
    | JsonApi.Item<Coach>
    | JsonApi.Item<Station>
    | JsonApi.Item<Vehicle>
    | JsonApi.Item<PreselectedAvailability>
  >
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SeatsLayoutApi {
  export interface Position {
    anchor_point: Vector3
    transformation: {
      rotation: Seat.Train.Rotation
      horizontal_flip: boolean
    }
  }

  export interface Seat {
    id: string
    characteristics: string[]
    item: string
    label: string
    position: Position
    remarks: string[]
    size: Seat.Size
  }

  export interface Response extends Omit<Seat.Train.Layout, 'seats'> {
    elements: Seat[]
  }
}

export default {
  load: makeApi.get<SeatsRequest, SeatsResponse>(
    { type: 'seats', old: 'bookings/seats', new: '/seats' },
    {
      transformResponse: <T, U, P>(response: T, rawResponse: U, requestParams: P) =>
        transformResponse(response as SeatsResponse, rawResponse, requestParams as SeatsRequest) as T,
    },
  ),
  availability: makeApi.get<SeatsAvailabilityApi.Request, SeatsAvailabilityApi.Response>('/seat_availabilities', {
    parseJsonApi: false,
  }),
  layout: (id: string) => makeApi.get<{}, SeatsLayoutApi.Response>(`/layouts/${id}`)({}),
}
