/* istanbul ignore file */
import React from 'react'

import Money from '@components/Money'
import SelectedSeatsBreakdown from '@components/Seats/Train/Mobile/SelectedSeatsBreakdown'
import config from '@config'
import useIsMobile from '@hooks/useIsMobile'
import currencyUtils from '@lib/currency'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Button } from '@ui'

interface Props {
  continueDisabled: boolean
  seatsCount: number
  selectedSeats: Seat.Selected[]
  fareClassName?: string
  onSubmit: (passengerCard?: DiscountCard.Item | null) => void
  farePrice?: Money
  seatsPrice: number
  allowSkip?: boolean
  schemeSegment: Seat.Train.Segment
  onClear?: () => void
}

const TrainSubmitSection = (props: Props) => {
  const {
    continueDisabled,
    seatsCount,
    selectedSeats,
    fareClassName,
    onSubmit,
    farePrice,
    seatsPrice,
    allowSkip,
    onClear,
    schemeSegment,
  } = props
  const { t } = useTranslation()
  const [{ currency, marketingCarrierCode }] = useParams()
  const isMobile = useIsMobile()
  const totalPrice = (farePrice: Money): Money => ({
    fractional: farePrice.fractional + seatsPrice,
    currency: farePrice.currency,
  })

  const formatedSeatsPrice = !!seatsPrice && (
    <div className="body-12 mt-1 color-grey-70">
      {t('seats.price', { price: currencyUtils.create(seatsPrice, currency).format() })}
    </div>
  )

  const isAncillary = config.seatAncillaryAsSelection[marketingCarrierCode!]

  return (
    <div className="column gap-3 px-sm-4 py-3">
      {!isAncillary && (
        <div className="cell">
          <div className="row gap-3 space-between-sm">
            <div className="cell no-grow basis-auto-sm cell-lg-6">
              {isMobile && (
                <SelectedSeatsBreakdown
                  schemeSegment={schemeSegment}
                  fareClass={fareClassName}
                  seats={selectedSeats}
                  max={seatsCount}
                />
              )}
            </div>
            <div className="cell no-grow cell-lg-6">
              <div className="row space-between body-20">
                {!isMobile && <div>{t('seats.total')}</div>}
                {farePrice && (
                  <div className="bolder">
                    <Money {...totalPrice(farePrice)} />
                  </div>
                )}
              </div>
              {!isMobile && formatedSeatsPrice}
            </div>
          </div>
          {isMobile && formatedSeatsPrice}
        </div>
      )}
      <div className="cell">
        <div className="row end gap-3">
          {allowSkip && selectedSeats.length === 0 && (
            <div className="cell-5 no-shrink cell-lg-6">
              <Button variant="outline" color="secondary" onClick={() => onSubmit()}>
                {t('seats.skip')}
              </Button>
            </div>
          )}
          {!!onClear && selectedSeats.length > 0 && (
            <div className="cell-5 no-shrink cell-lg-6">
              <Button variant="outline" color="secondary" onClick={onClear}>
                {t('seats.clear')}
              </Button>
            </div>
          )}
          <div className="cell cell-lg-6">
            <Button disabled={continueDisabled} variant="filled" color="secondary" onClick={() => onSubmit()}>
              {t('seats.continue')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainSubmitSection
