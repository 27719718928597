import React from 'react'

import { PaymentMethodStatus } from '@enums'
import { SubmitEventPayload } from '@hooks/useBookingFlow'
import utils from '@lib/utils'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import PaymentLabel from '@pages/Checkout/Payment/Label'

interface Props {
  type: PaymentMethodType
}

export const buildDelayedMethod = ({ type }: Props): PaymentMethod => ({
  status: PaymentMethodStatus.Ready,
  getOption: () => ({
    value: type,
    label: <PaymentLabel type={utils.string.toCamelcase(type)} showName />,
  }),
  provider: 'delayed',
  on: {
    submitForm: (): SubmitEventPayload => ({ paymentMethodData: { type } }),
  },
})
