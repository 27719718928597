import React, { ReactElement } from 'react'

import bem from '@lib/bem'
import { Icon } from '@ui'

import '@components/Seats/Bus/SegmentLabel/index.scss'

interface Props {
  segment: Seat.Segment
  connection: Connection | null
  onNavigateBack?: () => void
  currentIndex: number
  segmentsCount: number
  maxSegmentCount: number
}

const SegmentLabel = (props: Props): ReactElement => {
  const { segment, segmentsCount, onNavigateBack, connection, currentIndex, maxSegmentCount } = props
  const { departureStation, arrivalStation } = segment

  const getName = (stationCode: string): string | undefined => {
    const stations = connection?.segments.flatMap(({ departureStation, arrivalStation }) => [
      departureStation,
      arrivalStation,
    ])

    return stations?.find(({ code }) => stationCode === code)?.name
  }

  const departureStationName = getName(departureStation.code)
  const arrivalStationName = getName(arrivalStation.code)

  return (
    <div className="seat-selection__header-stations">
      {currentIndex >= 1 && onNavigateBack && <Icon onClick={onNavigateBack} name="back-arrow" size="medium" />}
      {segmentsCount > 1 && (
        <span className="cell-1-sm">
          <b>{currentIndex + 1}</b>
          <span>/</span>
          <span className={bem('seat-selection', 'header-stations-leg')}>{maxSegmentCount}</span>
        </span>
      )}
      {departureStationName && arrivalStationName && (
        <>
          <span className="seat-selection__header-stations-name cell-4-sm">{departureStationName}</span>
          <Icon name="arrow-right" size="medium" />
          <span className="seat-selection__header-stations-name cell-4-sm">{arrivalStationName}</span>
        </>
      )}
    </div>
  )
}

export default SegmentLabel
