import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PaymentMethodStatus } from '@enums'
import { SubmitEventPayload } from '@hooks/useBookingFlow'
import vgs, { VGS } from '@lib/vgs'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { useVgsValidate } from '@pages/Checkout/hooks/Payment/VGS/helpers/useValidate'
import { VGSCardData } from '@pages/Checkout/hooks/useInitialFormValues'
import VGSCard from '@pages/Checkout/Payment/CreditCardForm/VGS'
import PaymentLabel from '@pages/Checkout/Payment/Label'

const brands = [{ type: 'visa' }, { type: 'mc' }, { type: 'me' }]

const useCreditCard = (): PaymentMethod => {
  const [status, setStatus] = useState(PaymentMethodStatus.Pending)
  const [vgsForm, setVgsForm] = useState<VGS.Form>()
  const validate = useVgsValidate(vgsForm)

  const getOption = useCallback(
    () => ({
      value: 'credit_card',
      label: <PaymentLabel type="credit_card" brands={brands} showName />,
      content: <VGSCard vgsForm={vgsForm} />,
    }),
    [vgsForm],
  )

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const vgsForm = await vgs.init()

      setVgsForm(vgsForm)
      setStatus(PaymentMethodStatus.Ready)
    }

    void initialize()
  }, [])

  /* istanbul ignore next */
  const submitForm = useCallback(async (): Promise<SubmitEventPayload> => {
    if (!vgsForm) throw new Error('VGS is not ready')
    const { result } = await vgs.promisedTokenize<VGSCardData>(vgsForm)
    const paymentMethodData = { ...result, cardType: vgsForm?.state.cardNumber?.cardType }

    return { paymentMethodData, paymentMethod: 'credit_card' }
  }, [vgsForm])

  return useMemo(
    () => ({
      status,
      getOption,
      provider: 'vgs',
      on: {
        submitForm,
        validate,
      },
    }),
    [getOption, status, submitForm, validate],
  )
}

export default useCreditCard
