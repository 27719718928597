import AdyenCheckout from '@adyen/adyen-web'
import Core from '@adyen/adyen-web/core'
import '@adyen/adyen-web/dist/adyen.css'
import { useCallback, useEffect, useMemo, useState } from 'react'

import booking, { BookingFinalizeResponse, PaymentMethodsResponse } from '@api/booking'
import config from '@config'
import { FinishPaymentParams } from '@hooks/useBookingFlow'
import { Observable } from '@lib/observable'
import testingUtils from '@lib/testing'
import utils from '@lib/utils'
import { useFinalizeBooking } from '@loaders/finalizeBooking'
import { useApplePay } from '@pages/Checkout/hooks/Payment/Adyen/useApplePay'
import useBlik from '@pages/Checkout/hooks/Payment/Adyen/useBlik'
import { useCard } from '@pages/Checkout/hooks/Payment/Adyen/useCard'
import { useGooglePay } from '@pages/Checkout/hooks/Payment/Adyen/useGooglePay'
import { useMoovitCard } from '@pages/Checkout/hooks/Payment/Adyen/useMoovitCard'
import { useNativeGooglePay } from '@pages/Checkout/hooks/Payment/Adyen/useNativeGooglePay'
import usePaypal from '@pages/Checkout/hooks/Payment/Adyen/usePaypal'
import { usePix } from '@pages/Checkout/hooks/Payment/Adyen/usePix'
import { useTerminal } from '@pages/Checkout/hooks/Payment/Adyen/useTerminal'
import { PaymentMethod, MethodMap } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useFinalPrice } from '@pages/Checkout/hooks/usePrice'
import { useCheckout } from '@stores/checkout'
import { useCarrierSpecificParams } from '@stores/params'

const isAdyenPaymentMethod = (method?: PaymentMethod | null): method is AdyenTypes.Method =>
  method?.on != null && 'adyenFinalize' in method.on

interface Params {
  selectedMethod: AdyenTypes.MethodRef
  finishPayment: (params: FinishPaymentParams) => void
}

export const useAdyenMethods = ({ selectedMethod, finishPayment }: Params): MethodMap => {
  const [{ locale, retailerPartnerNumber, marketingCarrierCode }] = useCarrierSpecificParams()
  const [, , { get: getCheckout }] = useCheckout()
  const [adyen, setAdyen] = useState<Core>()
  const [additionalDetails] = useState(new Observable<any>())

  const onFinalize = useCallback(
    (response: BookingFinalizeResponse) => {
      if (isAdyenPaymentMethod(selectedMethod.current)) {
        selectedMethod.current.on?.adyenFinalize?.(response)
      }

      finishPayment(response)
    },
    [finishPayment, selectedMethod],
  )
  const finalizeMutation = useFinalizeBooking({ onSuccess: onFinalize })

  useEffect(() => {
    return additionalDetails.subscribe(paymentDetails => {
      const bookingFormId = getCheckout().bookingFormId
      /* istanbul ignore else */
      if (bookingFormId != null) finalizeMutation.mutate({ bookingFormId, paymentDetails })
    })
  }, [additionalDetails, finalizeMutation, getCheckout])

  const price = useFinalPrice()
  const initAdyen = useCallback(
    async (paymentMethods: PaymentMethodsResponse, price: Money): Promise<Core> => {
      const checkout = await AdyenCheckout({
        paymentMethodsResponse: { paymentMethods },
        amount: { value: price.fractional, currency: price.currency },
        showButton: false,
        locale,
        clientKey: config.adyen.publicKey,
        environment: config.adyen.env,
        onAdditionalDetails: (value: any) => {
          additionalDetails.set(value.data)
        },
      })
      testingUtils.expose('checkout', checkout)

      return checkout
    },
    [additionalDetails, locale],
  )

  const initialize = useCallback(
    async (price: Money) => {
      const methods = await booking.paymentMethods({
        amount: price.fractional,
        currency: price.currency,
        retailerPartnerNumber,
        marketingCarrierCode,
      })

      setAdyen(await initAdyen(methods, price))
    },
    [initAdyen, marketingCarrierCode, retailerPartnerNumber],
  )

  useEffect(() => {
    if (price) {
      void initialize(price)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize, utils.common.hash(price)])

  const override = useCallback(
    (method: PaymentMethod) => ({
      ...method,
      error: finalizeMutation.error ?? method.error,
      isLoading: finalizeMutation.isLoading || method.isLoading,
      provider: 'adyen',
      on: {
        ...method.on,
        submitForm: (data: CheckoutFormData) => {
          finalizeMutation.reset()

          return method.on?.submitForm?.(data)
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [finalizeMutation.error, finalizeMutation.isLoading, finalizeMutation.reset],
  )

  const pix = usePix(finishPayment, adyen)
  const applePay = useApplePay(adyen)
  const googlePay = useGooglePay(adyen)
  const creditCard = useCard(adyen)
  const paypal = usePaypal()
  const blik = useBlik(finishPayment)
  const terminal = useTerminal(finishPayment)
  const storedPayment = useMoovitCard()
  const nativeGooglePay = useNativeGooglePay()

  return useMemo(
    () => ({
      pix: override(pix),
      applePay: override(applePay),
      googlePay: override(googlePay),
      creditCard: override(creditCard),
      paypal: override(paypal),
      blik: override(blik),
      terminal: override(terminal),
      storedPayment: override(storedPayment),
      nativeGooglePay: override(nativeGooglePay),
    }),
    [override, pix, applePay, googlePay, creditCard, paypal, blik, storedPayment, terminal, nativeGooglePay],
  )
}
