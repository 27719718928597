import React, {
  ReactElement,
  ReactNode,
  UIEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import typesAPI from '@api/passengerTypes'
import ConnectionFilters from '@components/ConnectionFilters'
import SkeletonFilter from '@components/ConnectionFilters/Skeleton'
import ErrorBoundary from '@components/ErrorBoundary'
import JourneyCard, { ConnectionSelectionData } from '@components/JourneyCard'
import AlternativeTrip from '@components/JourneyList/AlternativeTrip'
import Emission from '@components/JourneyList/Emission'
import Header from '@components/JourneyList/Header'
import useAlternativeTrips from '@components/JourneyList/hooks/useAlternativeTrips'
import useCheckoutUrl from '@components/JourneyList/hooks/useCheckoutUrl'
import useConnectionsSearchParams from '@components/JourneyList/hooks/useConnectionsSearchParams'
import { useInboundFilter } from '@components/JourneyList/hooks/useInboundFilter'
import useTwoStepSearchParams from '@components/JourneyList/hooks/useTwoStepSearchParams'
import RetrySkeleton from '@components/JourneyList/Retry/Skeleton'
import ScrollButton from '@components/JourneyList/ScrollButton'
import TransportTabs from '@components/JourneyList/TransportTabs'
import OutboundTrip from '@components/OutboundTrip'
import VirtualList from '@components/VirtualList'
import config from '@config'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import useKavanaghIntegration from '@hooks/useKavanaghIntegration'
import useMoovitIntegration from '@hooks/useMoovitIntegration'
import useNavigate from '@hooks/useNavigate'
import useSearchParams from '@hooks/useSearchParams'
import amplitude from '@lib/analytics/amplitude'
import GTMDataLayer from '@lib/analytics/gtm/GTMdataLayer'
import bem from '@lib/bem'
import carrierUtils from '@lib/carrier'
import connectionUtils, { Emission as EmissionType } from '@lib/connection'
import filtering from '@lib/connections/filtering'
import { FilterOptions, TripDirection } from '@lib/connections/filters/types'
import groupConnections from '@lib/groupConnections'
import { Observable } from '@lib/observable'
import sort from '@lib/sorting'
import { useConnectionsLoader } from '@loaders/connections'
import { useLocationName } from '@loaders/locations'
import ScrollContext from '@pages/SearchResult/ScrollContext/scrollContext'
import useMediaQueries from '@queries/media'
import { useSettings } from '@queries/settings'
import { useConnectionFilters } from '@stores/connectionFilters'
import { useConnections } from '@stores/connections'
import { useParams } from '@stores/params'
import { Card, ErrorMessage, Skeleton } from '@ui'

import '@components/JourneyList/index.scss'

interface JourneyListProps {
  children?: ReactNode
}

const getItemId = (item: Connection | EmissionType): string => (item as Connection).id ?? 'emission'

const JourneyList = ({ children }: JourneyListProps): ReactElement => {
  const [queryParams, setQueryParams] = useSearchParams()
  const [params] = useParams()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [{ fareClasses, filter, quickReservation, transportationMode, media, passengerTypesList }] = useSettings()
  const { locale, departureLocation, arrivalLocation, retailerPartnerNumber } = params

  const [isInitialSearch, setIsInitialSearch] = useState(true)
  const [isFiltersModalOpened, setIsFiltersModalOpened] = useState<boolean>(false)
  const [isConnectionsLoading, setIsConnectionsLoading] = useState<boolean>(false)
  const [outboundData, setOutboundData] = useState<ConnectionSelectionData | null>(null)
  const { isJJKEnabled } = useKavanaghIntegration()
  const { enabledWithWeb: isMoovitEnabled, filterConnections } = useMoovitIntegration()
  /* Because Demand team can't handle amendment calculation for JJK implemented temporarily solution
   in order to meet deadline */
  const forcedFreeTripPrice = useMemo(() => isJJKEnabled && !!params.bookingId, [isJJKEnabled, params.bookingId])

  const { data: departure } = useLocationName({ locale, location: departureLocation })
  const { data: arrival } = useLocationName({ locale, location: arrivalLocation })

  const [{ outbounds, inbounds }, setConnections] = useConnections()
  const [filters, , { reset: resetFilters }] = useConnectionFilters()

  const isLocationLoaded = !!departure && !!arrival
  const isOutboundSelected = outboundData !== null
  const fareClassFilter = useMemo(() => {
    if (Object.keys(config.forcedFareClass).includes(String(retailerPartnerNumber))) return params.fareClass
    if (quickReservation.enabled) return null

    return outboundData?.fareClassCode ?? params.fareClass
  }, [outboundData?.fareClassCode, params.fareClass, quickReservation.enabled, retailerPartnerNumber])
  const isMediaEnabled = media.enabled && (media.displayOn === 'search_results' || media.displayOn === 'everywhere')
  const twoWayConnections = useMemo(() => params.returnDate != null, [params.returnDate])

  const connectionsParams = useConnectionsSearchParams()
  const twoStepSearchParams = useTwoStepSearchParams(outboundData, twoWayConnections)
  const { data, isLoading, errorCode } = useConnectionsLoader({ ...connectionsParams, ...twoStepSearchParams })

  const groupedConnections = useMemo(
    () => {
      if (!data) return null
      if (isMoovitEnabled) return filterConnections(groupConnections(data, { ...params, ...twoStepSearchParams }))

      return groupConnections(data, { ...params, ...twoStepSearchParams })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )
  useEffect(() => {
    let connections = groupedConnections ?? { outbounds: [], inbounds: [] }

    if (twoStepSearchParams && isOutboundSelected) connections = { outbounds, inbounds: connections.inbounds }
    setConnections(connections)

    if (groupedConnections) {
      const connectionsCount = Object.values(connections).flat().length
      GTMDataLayer.pushConnectionResultsEvent({ ...params, connectionsCount })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedConnections, setConnections])

  // ToDo: Remove?
  useEffect(() => {
    setIsConnectionsLoading(isLoading)
  }, [isLoading])

  const alternativeTrips = useAlternativeTrips({
    data: groupedConnections,
    params: connectionsParams,
    error: errorCode,
  })

  const isContentLoading = isConnectionsLoading || alternativeTrips.isLoading || isLoading

  useEffect(() => {
    if (isLocationLoaded && isInitialSearch && data) {
      amplitude.results.load({
        departure,
        arrival,
        isInitialSearch,
        resultCount: data.length,
        alternativeTrips: !!alternativeTrips.altParams,
      })
      setIsInitialSearch(false)
    }
    // eslint-disable-next-line
  }, [isLocationLoaded, data])

  const isLocalScroll = isMobile || isTablet
  const scrollToTop = () => {
    const ref = isLocalScroll ? listRef : scrollRef
    // istanbul ignore else
    if (ref?.current) {
      ref.current.style.overflow = 'hidden' // stops scroll inertia on touch screens
      ref.current.scrollTo({ top: 0 })
      requestAnimationFrame(() => {
        ref.current?.style.removeProperty('overflow')
      })
    }
  }

  const listRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  const filterInbounds = useInboundFilter(outboundData?.connection, fareClassFilter)
  const connections = useMemo<Connection[]>(
    () => (isOutboundSelected ? filterInbounds(inbounds) : outbounds),
    [isOutboundSelected, filterInbounds, inbounds, outbounds],
  )
  const filterOptions = useMemo<FilterOptions>(
    () => ({ byCheapest: !['everywhere', 'search_results'].includes(fareClasses.displayOn) }),
    [fareClasses.displayOn],
  )
  const transformedList = useMemo(() => {
    const data = filtering.applyFilters(connections, filters, filterOptions)

    return sort.sortConnectionList(data, params.sorting)
  }, [connections, filterOptions, filters, params.sorting])

  const buildRedirectUrl = useCheckoutUrl()
  const { checkoutRedirect } = useNavigate()

  const handleConnectionFlow = async (data: ConnectionSelectionData): Promise<void> => {
    const { connection, fareClassCode, passengerCard } = data
    connection && amplitude.results.clickTrip(connection, fareClassCode, transformedList)
    let passengers = params.passengers
    if (passengerCard !== undefined) {
      const cards = passengerCard
        ? [{ ...params.passengers?.[0].cards?.[0], name: passengerCard.name }]
        : /* istanbul ignore next */ []
      passengers = params.passengers?.[0] ? [{ ...params.passengers?.[0], cards }] : /* istanbul ignore next */ null
    }

    if (twoWayConnections && !isOutboundSelected) {
      if (passengerCard !== undefined) setQueryParams({ ...queryParams, passengers })
      scrollToTop()
      setOutboundData(data)
    } else {
      const tripData = {
        outbound: outboundData ?? data,
        inbound: outboundData ? data : null,
        passengers,
        passengerTypes:
          passengerTypesList.enabled && passengerTypesList.multiple
            ? await typesAPI.load({ marketingCarrierCode: connection.marketingCarrier.code }).catch(() => null)
            : null,
      }

      const checkoutUrl = buildRedirectUrl(tripData)

      // Temporarily, will be used in the future
      // if (isMoovitEnabled) {
      //   initiateGetUserData(tripData)
      //   return
      // }

      checkoutRedirect(checkoutUrl)
    }
  }

  const handleClickDetailsAmplitude = (connection: Connection): void => {
    amplitude.results.clickDetails(connection, transformedList)
  }

  const { scrollObservable: outerScroll, scrollRef } = useContext(ScrollContext)
  const scrollObservable = useRef(new Observable<UIEvent>())

  const resetConnection = (): void => setOutboundData(null)
  useEffect(() => {
    if (!twoWayConnections) resetConnection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoWayConnections])

  useEffect(() => {
    const updatedOutbound = outbounds.find(item => item.id === outboundData?.connection?.id)
    if (updatedOutbound) setOutboundData(data => data && { ...data, connection: updatedOutbound })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbounds])

  useEffect(() => {
    resetFilters(['transportMode'])
  }, [connections, resetFilters])

  const closeFiltersModal = useCallback(() => {
    setIsFiltersModalOpened(false)
  }, [])

  const [mediaData] = useMediaQueries({ connections: transformedList, enabled: isMediaEnabled })
  const renderJourneyCard = (connection: Connection): ReactNode => (
    <JourneyCard
      connection={connection}
      onSelect={handleConnectionFlow}
      fareClassFilter={fareClassFilter}
      onDetailsOpen={handleClickDetailsAmplitude}
      isInbound={isOutboundSelected}
      disableFareFilter={quickReservation.enabled}
      seatsRequirement={outboundData?.seats?.[0]?.length}
      media={carrierUtils.getCarrierMedia(mediaData, carrierUtils.getCarrierCodes(connection))}
      forcedFreeTripPrice={forcedFreeTripPrice}
    />
  )

  const renderEmission = (emission: EmissionType): ReactNode => (
    <div className="journey-list__emission-card">
      <Card body={<Emission position="bottom-end" {...emission} />} />
    </div>
  )

  const renderElement = (item: Connection | EmissionType): ReactNode => {
    if ('typicalEmission' in item) return renderEmission(item)

    return renderJourneyCard(item)
  }

  const virtualListItems = useMemo(() => {
    const emission = connectionUtils.calculateEmission(transformedList)
    const POSITION = 1

    if (!emission) return transformedList

    return [...transformedList.slice(0, POSITION), emission, ...transformedList.slice(POSITION)]
  }, [transformedList])

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    scrollObservable.current.set(event)
  }
  const tripDirection = useMemo<TripDirection>(() => {
    if (params.returnDate == null) return 'oneWay'
    return isOutboundSelected ? 'inbound' : 'outbound'
  }, [isOutboundSelected, params.returnDate])

  return (
    <ScrollContext.Provider
      value={{
        scrollRef: isLocalScroll ? listRef : scrollRef,
        scrollObservable: isLocalScroll ? scrollObservable.current : outerScroll,
      }}
    >
      <ScrollButton listRef={listRef} onClick={scrollToTop} />
      <div ref={listRef} className="journey-list column" onScroll={onScroll}>
        {outboundData && (
          <OutboundTrip
            connection={outboundData.connection}
            fareClassCode={fareClassFilter ?? outboundData.fareClassCode}
            seats={outboundData.seats}
            onClick={resetConnection}
            isAmendment={!!params.bookingId}
          />
        )}
        <div className="row-lg column">
          {isLocalScroll && <div className={bem('journey-list', 'backdrop')} />}
          {!errorCode && filter.enabled && (
            <ErrorBoundary fallback={() => <></>}>
              <div className="journey-list__filters column">
                {isContentLoading && !isMobile && <SkeletonFilter />}
                {!isContentLoading && (
                  <ConnectionFilters
                    tripDirection={tripDirection}
                    connections={connections}
                    foundConnectionsCount={transformedList.length}
                    opened={isFiltersModalOpened}
                    onClose={closeFiltersModal}
                    options={filterOptions}
                  />
                )}
              </div>
            </ErrorBoundary>
          )}
          <div className="journey-list__container">
            {transportationMode.enabled && (
              <TransportTabs
                connections={connections}
                transportListType={transportationMode.supported}
                isLoading={isContentLoading}
              />
            )}
            {alternativeTrips.altParams && !isContentLoading && (
              <AlternativeTrip
                altParams={alternativeTrips.altParams}
                departureLocationName={departure?.name}
                arrivalLocationName={arrival?.name}
              />
            )}
            <Header
              ref={titleRef}
              outbound={outboundData?.connection}
              twoWayConnections={twoWayConnections}
              onFiltersOpened={() => {
                setIsFiltersModalOpened(true)
              }}
              isLoading={isContentLoading}
            />
            {errorCode && <ErrorMessage code={errorCode} />}
            {!errorCode && (
              <div className="journey-list__cards">
                <Skeleton.List Skeleton={RetrySkeleton} loading={isContentLoading} amount={1}>
                  <VirtualList getId={getItemId} items={virtualListItems} renderElement={renderElement} gap={25} />
                </Skeleton.List>
                {!transformedList.length && !isContentLoading && <ErrorMessage code="emptyResult" />}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </ScrollContext.Provider>
  )
}

export default JourneyList
