import React, { createContext, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import ErrorPage from '@components/ErrorPage'
import useNotFoundPath from '@hooks/useNotFoundPath'
import amplitude from '@lib/analytics/amplitude'
import { DataNotFoundError } from '@lib/analytics/amplitude/error'
import { ApiError } from '@lib/api'
import { LoaderHook } from '@lib/loader'

interface RequiredResourcesProps<T> {
  loader: LoaderHook<T | null>
  isValidData?: (data: T) => boolean
  children: ReactElement
  isErrorPage?: boolean
}

interface ResourceContextState {
  isLoading: boolean
  error: ApiError | null
}

export const ResourceContext = createContext<ResourceContextState>({ isLoading: false, error: null })

const RequiredResource = <T,>({
  loader,
  children,
  isValidData,
  isErrorPage,
}: RequiredResourcesProps<T>): ReactElement => {
  const notFoundPath = useNotFoundPath()

  if (loader.error != null) {
    if (isErrorPage) return <ErrorPage error={loader.error} />
    else return <Navigate to={notFoundPath} state={{ error: amplitude.error.buildParams(loader.error) }} />
  }

  const isDataValid = loader.data != null && (isValidData?.(loader.data) ?? true)
  if (!loader.isLoading && !isDataValid) {
    return <Navigate to={notFoundPath} state={{ error: amplitude.error.buildParams(new DataNotFoundError()) }} />
  }

  return <ResourceContext.Provider value={{ ...loader }}>{children}</ResourceContext.Provider>
}

export default RequiredResource
