import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'

import { PaymentMethodSetting, PaymentProvidersMethodsSetting } from '@api/settings'
import { PaymentMethodStatus } from '@enums'
import { FinishPaymentParams, PaymentEvents } from '@hooks/useBookingFlow'
import useMoovitIntegration from '@hooks/useMoovitIntegration'
import utils from '@lib/utils'
import { useAdyenMethods } from '@pages/Checkout/hooks/Payment/Adyen/useAdyenMethods'
import { useDelayedMethods } from '@pages/Checkout/hooks/Payment/Delayed/useDelayedMethods'
import { useOtherMethods } from '@pages/Checkout/hooks/Payment/Other/useOtherMethods'
import { useVGSMethods } from '@pages/Checkout/hooks/Payment/VGS/useVGSMethods'
import { useSettings } from '@queries/settings'
import { ExpandableRadioOption } from '@ui/RadioGroup/Expandable'

export type OptionType = ExpandableRadioOption<PaymentMethodType>

export interface PaymentMethod<TEvents extends PaymentEvents = PaymentEvents> {
  getOption: () => OptionType | OptionType[] | null | undefined
  status: PaymentMethodStatus
  provider?: string
  extraContent?: ReactNode
  getPayButton?: () => ReactElement
  error?: Error | null
  isLoading?: boolean
  isSubmitting?: boolean
  on?: TEvents
}

export type MethodMap = Record<string, PaymentMethod>
type ProvidersMap = Record<PaymentProvider | 'other', MethodMap>

interface PaymentMethodsHookResult {
  available: PaymentMethod[]
  selected?: PaymentMethod | null
}

interface PaymentMethodsHookParams {
  methodType?: PaymentMethodType | null
  confirmationOnly?: boolean
  finishPayment: (params: FinishPaymentParams) => void
}

const methodsOrder = [
  'savedCreditCard',
  'pix',
  'applePay',
  'googlePay',
  'nativeGooglePay',
  'paypal',
  'creditCard',
  'storedPayment',
  'blik',
  'holdReservation',
  'terminal',
  'cash',
  'invoice',
]

export const useBookingPayment = ({
  methodType,
  confirmationOnly,
  finishPayment,
}: PaymentMethodsHookParams): PaymentMethodsHookResult => {
  const [{ paymentProvidersMethods, holdBooking, amtrackSavedCards }] = useSettings()
  const selectedMethodRef = useRef<PaymentMethod | null>()

  const adyen = useAdyenMethods({ selectedMethod: selectedMethodRef, finishPayment })
  const vgs = useVGSMethods()
  const delayed = useDelayedMethods()
  const other = useOtherMethods()
  const moovit = useMoovitIntegration()

  const extendedMethodSettings = useMemo<Record<string, PaymentMethodSetting>>(() => {
    return {
      ...paymentProvidersMethods,
      savedCreditCard: amtrackSavedCards.enabled ? { provider: 'vgs' } : null,
      holdReservation: !confirmationOnly && holdBooking.enabled ? { provider: null } : null,
      nativeGooglePay: moovit.enabled && !!paymentProvidersMethods.googlePay ? { provider: 'adyen' } : null,
      googlePay: !moovit.enabled && !!paymentProvidersMethods.googlePay ? { provider: 'adyen' } : null,
    }
  }, [amtrackSavedCards.enabled, confirmationOnly, holdBooking.enabled, moovit.enabled, paymentProvidersMethods])

  const providers = useMemo<ProvidersMap>(() => ({ adyen, vgs, delayed, other }), [adyen, delayed, other, vgs])
  const findMethod = useCallback(
    (paymentMethodType: PaymentMethodType): PaymentMethod | undefined => {
      const type = utils.string.toCamelcase(paymentMethodType.split(':')[0]) as keyof PaymentProvidersMethodsSetting
      const setting = extendedMethodSettings[type]

      return setting ? providers[setting.provider ?? 'other']?.[type] : undefined
    },
    [extendedMethodSettings, providers],
  )

  const availableMethods = useMemo(() => {
    return methodsOrder.reduce<PaymentMethod[]>((methods, type) => {
      const method = findMethod(type)

      return method ? [...methods, method] : methods
    }, [])
  }, [findMethod])

  const selectedMethod = useMemo(() => (methodType ? findMethod(methodType) : null), [findMethod, methodType])
  useEffect(() => {
    selectedMethodRef.current = selectedMethod
  }, [selectedMethod])

  return { available: availableMethods, selected: selectedMethod }
}
