/* istanbul ignore file */
import React, { useMemo } from 'react'

import SeatsLoader from '@components/JourneyList/SeatsLoader'
import SeatSelectionBus from '@components/Seats/Bus'
import SeatSelectionTrain from '@components/Seats/Train'
import seatSelectionUtils from '@lib/seatSelection'
import useSeatsQueries from '@queries/seats'
import useTwoStepSeatsQuery from '@queries/seats/twoSteps'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

export interface ResultsPageSeatsForm extends Omit<Seat.SubmitData, 'seats'> {
  seats: Seat.BySegment
}

interface Props {
  onClose: () => void
  connection: Connection
  fareFilter?: string | null
  initialFare: string
  onFareChange: (fareClass: string) => void
  onSubmit: (data: ResultsPageSeatsForm) => void
  outboundSeatsCount?: number
  pax: number
}

const SeatsModal = (props: Props) => {
  const { onClose, connection, fareFilter, initialFare, onFareChange, onSubmit, outboundSeatsCount, pax } = props
  const [params] = useParams()
  const [{ paxDiscountCodes, seatSelection }] = useSettings()

  const isLimitedDiscountFlow = paxDiscountCodes.enabled && paxDiscountCodes.displayOn === 'search_results'
  const isTrain = connection.segments[0].vehicle?.vehicleType?.code === 'TRAIN'

  const seatsProps = {
    connection,
    options: {
      limitations: isLimitedDiscountFlow,
      fareClass: isTrain || seatSelection.sendFareClass ? initialFare : null,
    },
  }

  const [data, meta, refetch] = useSeatsQueries({ ...seatsProps, enabled: seatSelection.flowType === 'single_step' })
  const trainSeatsTwoStep = useTwoStepSeatsQuery({ ...seatsProps, enabled: seatSelection.flowType === 'two_step' })
  const isSeatsLoaded = seatSelection.flowType === 'single_step' ? !meta.isLoading : !trainSeatsTwoStep.isLoading
  const error = seatSelection.flowType === 'single_step' ? meta.error : trainSeatsTwoStep.error
  const layout = useMemo(() => ({ outbound: data ?? [], inbound: [] }), [data])

  const submit = (data: Seat.SubmitData): void => {
    onSubmit({ ...data, seats: data.seats.outbound })
  }

  const trainSeats = useMemo(
    () =>
      seatSelection.flowType === 'single_step'
        ? seatSelectionUtils.transformIntoTrainSeats(layout.outbound)
        : { layoutQuery: trainSeatsTwoStep.layoutQuery, scheme: trainSeatsTwoStep.scheme },
    [layout.outbound, trainSeatsTwoStep.layoutQuery, trainSeatsTwoStep.scheme, seatSelection.flowType],
  )

  return (
    <>
      {(!isSeatsLoaded || error) && (
        <SeatsLoader loading={meta.isLoading} error={error?.code} refetch={refetch} onClose={onClose} />
      )}
      {isSeatsLoaded && !error && (
        <>
          {!isTrain && (
            <SeatSelectionBus
              opened
              layout={layout}
              fareFilter={fareFilter}
              reservedSeatsCount={params.pax}
              outboundSeatsCount={outboundSeatsCount}
              onSubmit={submit}
              onClose={onClose}
              connections={{ outbound: connection, inbound: null }}
              initialFare={initialFare}
              isFullPrice
              requiredSeats={isLimitedDiscountFlow ? pax : undefined}
              discountDropdown={isLimitedDiscountFlow && pax === 1}
            />
          )}
          {isTrain && (
            <SeatSelectionTrain
              {...trainSeats}
              allowSkip
              opened
              reservedSeatsCount={params.pax}
              onSubmit={submit}
              onClose={onClose}
              connection={connection}
              selectedFare={initialFare}
              onFareClassChange={onFareChange}
            />
          )}
        </>
      )}
    </>
  )
}

export default SeatsModal
