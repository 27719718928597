import { useMemo } from 'react'

import useHoldReservation from '@pages/Checkout/hooks/Payment/Other/useHoldReservation'
import { MethodMap } from '@pages/Checkout/hooks/Payment/useBookingPayment'

export const useOtherMethods = (): MethodMap => {
  const holdReservation = useHoldReservation()

  return useMemo(() => ({ holdReservation }), [holdReservation])
}
