import { useEffect, useRef, useState } from 'react'

import config from '@config'
import locationUtils from '@lib/location'
import utils from '@lib/utils'
import { ConnectionsLoaderParams } from '@loaders/connections'
import { useParams } from '@stores/params'

type AltParams = Pick<
  Partial<ConnectionsLoaderParams>,
  'departureLocation' | 'arrivalLocation' | 'returnDate' | 'departureDate'
>

interface HookProps {
  data?: SortedConnections | null
  error?: ErrorCode | null
  params: ConnectionsLoaderParams
}

interface HookResult {
  altParams: AltParams | null
  isLoading: boolean
}

const getRetryParams = (
  currentParams: ConnectionsLoaderParams,
  initialParams: ConnectionsLoaderParams,
  previousParams: ConnectionsLoaderParams | null,
): HookResult['altParams'] => {
  const newLocations = locationUtils.getRetryLocations(currentParams)
  const isDifferentLocations =
    currentParams.departureLocation?.code !== previousParams?.arrivalLocation?.code &&
    currentParams.arrivalLocation?.code !== previousParams?.departureLocation?.code

  if (newLocations !== null) return newLocations
  if (currentParams.returnDate != null)
    return {
      departureLocation: initialParams.departureLocation,
      arrivalLocation: initialParams.arrivalLocation,
      returnDate: null,
    }
  if (
    initialParams.returnDate != null &&
    initialParams.departureDate === currentParams.departureDate &&
    isDifferentLocations
  )
    return {
      departureLocation: currentParams.arrivalLocation,
      arrivalLocation: currentParams.departureLocation,
      departureDate: initialParams.returnDate,
      returnDate: null,
    }

  return null
}

const useAlternativeTrips = ({ data, params, error }: HookProps): HookResult => {
  const [altParams, setAltParams] = useState<AltParams | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [{ retailerPartnerNumber }, setParams] = useParams()
  const initialParamsRef = useRef<ConnectionsLoaderParams | null>(null)
  const lastTryParamsRef = useRef<ConnectionsLoaderParams | null>(null)
  const isTwoStepSearch = config.twoStepSearch.byRpn.includes(retailerPartnerNumber)

  useEffect(() => {
    if (error) {
      setAltParams(null)
    }
  }, [error])

  const initSearch = () => {
    setAltParams(null)
    initialParamsRef.current = params
  }

  const abortSearch = () => {
    setAltParams(null)
    setIsLoading(false)
    // istanbul ignore else: cannot be triggered with the current implementation
    if (initialParamsRef.current) {
      const { passengers: _, ...params } = initialParamsRef.current

      setParams(params)
    }
  }

  const finishSearch = () => {
    setIsLoading(false)
    initialParamsRef.current = null
  }

  const searchForAlternativeTrips = () => {
    const retryParams = getRetryParams(params, initialParamsRef.current!, lastTryParamsRef.current)
    lastTryParamsRef.current = params
    if (!retryParams) return abortSearch()

    setParams(retryParams)
    setIsLoading(true)
    setAltParams(prev => ({ ...prev, ...retryParams }))
  }

  useEffect(() => {
    if (!data) return
    if (isTwoStepSearch) return

    if (initialParamsRef.current != null && utils.object.isEqual(initialParamsRef.current, params)) return
    if (!isLoading) initSearch()

    if (data.outbounds.length === 0) searchForAlternativeTrips()
    else finishSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    altParams,
    isLoading,
  }
}

export default useAlternativeTrips
