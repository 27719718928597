import camelCase from 'camelcase'
import React, { ReactElement } from 'react'

import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import image from '@lib/image'
import { useSettings } from '@queries/settings'
import { Icon, Tooltip } from '@ui'

import '@components/JourneyCard/Header/index.scss'

interface HeaderProps {
  connection: Connection
  departureDate?: string
  onClick?: (fareClassCode: string) => void
}

const Header = ({ connection, departureDate, onClick }: HeaderProps): ReactElement => {
  const { t } = useTranslation()
  const [{ carrierLogo }] = useSettings()

  const key = camelCase(carrierLogo.source)
  const uniqueCarrierCodes = [...new Set(connection.segments.map(item => (item[key as keyof Segment] as Carrier).code))]
  const segmentsCount = connection.segments.filter(segment => !connectionUtils.isSelfTransfer(segment)).length
  const containSelfTransfer = connection.segments.some(connectionUtils.isSelfTransfer)
  const tripDirection =
    segmentsCount === 1 ? t('card.directTrip') : t('card.numberOfTransfers', { count: segmentsCount - 1 })

  return (
    <div className="journey-card__header" onClick={() => onClick?.(connection.cheapestFareClassCode)}>
      <div className="journey-card__header-logo cell-8">
        {uniqueCarrierCodes.slice(0, 2).map(item => (
          <img
            key={item}
            src={image.getMarketingCarrierLogo(item)}
            className="journey-card__header-logo-img"
            alt="carrier logo"
          />
        ))}
        {uniqueCarrierCodes.length > 2 && (
          <span className="journey-card__header-logo-count">{`+${uniqueCarrierCodes.length - 2}`}</span>
        )}
        {departureDate && <div className="row items-center">{tripDirection}</div>}
      </div>
      <div className="journey-card__header-trip-type row end">
        {containSelfTransfer && (
          <Tooltip action="hover" content={t('card.selfTransferWarning')} position="top">
            <Icon className="journey-card__header-trip-type-icon" name="alert" size="small" />
          </Tooltip>
        )}
        <div>
          {departureDate && <b>{departureDate}</b>}
          {!departureDate && tripDirection}
        </div>
      </div>
    </div>
  )
}

export default Header
