/* istanbul ignore file */
import { useCallback, useMemo, useState } from 'react'

import utils from '@lib/utils'

interface ItemSelection {
  items: Seat.ByTripDirection
  toggle: (seat: Seat.Selected, direction: ConnectionType, segment: number) => void
  set: (items: Seat.ByTripDirection) => void
  // ToDo: we can remove it after some refactoring
  setSegment: (seats: Seat.Selected[], direction: ConnectionType, segment: number) => void
}

const useItemsSelection = (initialData?: Seat.ByTripDirection | null) => {
  const [items, setItems] = useState<Seat.ByTripDirection>(initialData ?? { outbound: {}, inbound: {} })

  const toggle = useCallback<ItemSelection['toggle']>((seat, direction, segment) => {
    setItems(items => {
      const selectedSeats = items[direction][segment] ?? []

      return {
        ...items,
        [direction]: {
          ...items[direction],
          [segment]: utils.array.toggle({ ...seat, segmentIndex: segment }, selectedSeats, 'code'),
        },
      }
    })
  }, [])

  const setSegment = useCallback<ItemSelection['setSegment']>((seats, direction, segment) => {
    setItems(items => ({
      ...items,
      [direction]: {
        ...items[direction],
        [segment]: seats,
      },
    }))
  }, [])

  return useMemo(
    () => ({
      items,
      toggle,
      setSegment,
      set: setItems,
      clear: () => setItems({ outbound: {}, inbound: {} }),
    }),
    [items, setSegment, toggle],
  )
}

export default useItemsSelection
