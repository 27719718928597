import React, { ReactElement } from 'react'

import ConnectionBreakdown, { ConnectionBreakdownData } from '@components/TripBreakdown/ConnectionBreakdown'
import carrierUtils from '@lib/carrier'
import { useTranslation } from '@lib/i18n'
import weeklyUtils from '@lib/weekly'
import useTimetable from '@pages/Checkout/hooks/useTimetable'
import useValidity from '@pages/Checkout/hooks/useValidity'
import { useParams } from '@stores/params'

import '@components/TripBreakdown/index.scss'

interface TripBreakdownProps {
  express?: boolean
  timetable?: boolean
  outbound: ConnectionBreakdownData | null
  inbound: ConnectionBreakdownData | null
  carriers?: MarketingCarrier[]
  isCarriersLoading: boolean
  fareClass?: string
  connections?: SortedConnections | null
  weeklyConnections?: WeeklyConnectionsParams
}

const TripBreakdown = (props: TripBreakdownProps): ReactElement => {
  const {
    express,
    outbound,
    inbound,
    carriers = [],
    isCarriersLoading,
    fareClass,
    weeklyConnections,
    timetable,
    connections,
  } = props
  const { t } = useTranslation()
  const [params] = useParams()
  const isTwoWayTrip = !!inbound
  const weeklyTripsCount = weeklyUtils.getConnectionsCount(weeklyConnections)
  const weeklyTripsDates = weeklyUtils.getDatesRange(weeklyConnections)
  const connectionBreakdownProps = {
    showTime: !express && !weeklyTripsCount,
    showCarrier: !express && !!carriers.length,
    showDuration: express,
    hideLocations: params.hideLocations,
  }
  const { outboundValidity, inboundValidity } = useValidity({ fareClass, carriers, inbound, outbound })
  const list = useTimetable({ connections, enabled: timetable })

  const getTitle = (): string | null => {
    if (weeklyTripsCount) return t('checkout.bookingDetails.weeklyTrip', { count: weeklyTripsCount })
    if (isTwoWayTrip) return t('checkout.bookingDetails.outboundTrip')

    return null
  }

  return (
    <div className="trip-breakdown">
      {outbound && (
        <ConnectionBreakdown
          {...connectionBreakdownProps}
          type="outbound"
          connection={outbound}
          carriers={carrierUtils.getCarriers(outbound, carriers)}
          title={getTitle()}
          fareClass={fareClass}
          validity={outboundValidity}
          isLoading={isCarriersLoading}
          weeklyDates={weeklyTripsDates}
          timetable={{ list, connections: list?.outbounds }}
        />
      )}
      {inbound && !weeklyTripsCount && (
        <>
          <hr />
          <ConnectionBreakdown
            {...connectionBreakdownProps}
            type="inbound"
            connection={inbound}
            carriers={carrierUtils.getCarriers(inbound, carriers)}
            title={t('checkout.bookingDetails.returnTrip')}
            fareClass={fareClass}
            validity={inboundValidity}
            isLoading={isCarriersLoading}
            timetable={{ list, connections: list?.inbounds }}
          />
        </>
      )}
    </div>
  )
}

export default TripBreakdown
