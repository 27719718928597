/* istanbul ignore file */

import cn from 'classnames'
import React, { useContext } from 'react'

import SegmentLabel from '@components/Seats/Bus/SegmentLabel'
import TrainCarInfo from '@components/Seats/Train/CarInfo'
import CarSelection from '@components/Seats/Train/CarSelector'
import { TrainSeatSelectionContext } from '@components/Seats/Train/context'
import TrainDeck from '@components/Seats/Train/Deck'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Dropdown } from '@ui'

import '@components/Seats/Train/Mobile/index.scss'

const TrainSeatsMobile = () => {
  const {
    connection,
    fareClass,
    segmentsCount,
    currentSegment,
    fareOptions,
    setFareClass,
    carIndex,
    setCarIndex,
    disableFare,
    onSelect,
    selectedSeats,
    onNavigateBack,
    maxSegmentCount,
    layout,
    schemeSegment,
    connectionSegment,
  } = useContext(TrainSeatSelectionContext)
  const { t } = useTranslation()
  const handleChange = (fareClass: string): void => {
    setFareClass?.(fareClass)
    setCarIndex(0)
  }

  return (
    <div className="column train-seats">
      <div className="cell no-grow">
        <div className="column">
          {segmentsCount > 1 && (
            <div className="cell no-grow px-4 pb-3">
              <SegmentLabel
                segment={connectionSegment}
                segmentsCount={segmentsCount}
                connection={connection}
                currentIndex={currentSegment}
                onNavigateBack={onNavigateBack}
                maxSegmentCount={maxSegmentCount}
              />
            </div>
          )}
          <div className="cell no-grow px-4 pb-4">
            <Dropdown
              label={t('seats.fareClass')}
              disabled={disableFare || !setFareClass}
              items={fareOptions}
              value={fareClass}
              onChange={handleChange}
            />
          </div>
          <div className="cell no-grow">
            <CarSelection cars={schemeSegment.cars} onChange={setCarIndex} value={carIndex} />
          </div>
        </div>
      </div>
      <div className={cn('cell', bem('train-seats', 'deck-section'))}>
        <div className={bem('train-seats', 'deck-section-scrollable')}>
          <div className="px-3 py-4 column items-center">
            <TrainCarInfo
              seats={layout?.seats ?? []}
              connection={connection}
              label={schemeSegment.cars.find(car => car.index === carIndex)!.label}
              fareClass={fareClass}
            />
            <TrainDeck
              deckSize={layout?.size}
              seats={layout?.seats ?? []}
              carIndex={carIndex}
              onClick={onSelect}
              selectedSeats={selectedSeats}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainSeatsMobile
