import { FieldHookConfig, useField } from 'formik'
import React, { useContext } from 'react'

import amplitude from '@lib/analytics/amplitude'
import AncillaryContext from '@pages/Checkout/Extras/Ancillary/Context'
import Select from '@pages/Checkout/Extras/Card/Select'
import usePassengersCount from '@pages/Checkout/hooks/usePassengersCount'

export type AncillaryFieldData = ReturnType<typeof useField<Ancillary.Item[]>>

interface Props {
  override?: (ancillary: Ancillary.Item) => Ancillary.Item
  onToggle?: (ancillary: Ancillary.Item | null, fieldProps: AncillaryFieldData) => void | boolean
  fieldConfig?: Partial<FieldHookConfig<Ancillary.Item[]>>
}

const AncillarySelect = ({ override, onToggle, fieldConfig }: Props) => {
  const { ancillary } = useContext(AncillaryContext)
  const fieldProps = useField<Ancillary.Item[]>({ name: `ancillaries.${ancillary.category}`, ...fieldConfig })
  const [{ value }, , { setValue }] = fieldProps
  const maxCount = usePassengersCount()

  const toggleSelect = (ancillary: Ancillary.Item): void => {
    const count = maxCount - value.length

    if (onToggle && onToggle(count === 0 ? null : ancillary, fieldProps) === false) {
      return
    }

    const ancillaries = [...Array(count)].map((_, index) => ({
      ...(override?.(ancillary) ?? ancillary),
      passengerIndex: index + 1,
    }))
    setValue(ancillaries, false)
    amplitude.checkout.changeAncillary(ancillary.name, count)
  }

  return <Select ancillary={ancillary} onClick={toggleSelect} selected={value.length > 0} removable />
}

export default AncillarySelect
