import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import paramsUtils from '@lib/params'
import urlUtils from '@lib/url'
import { ParamsStore, useParams } from '@stores/params'

interface SmartLinkProps {
  to?: string
  popup?: boolean
  className?: string
  onClick?: (event: React.MouseEvent, url: string) => void
  children?: React.ReactNode
}

interface LinkProps extends SmartLinkProps {
  to: string
}

const adjustLinkParams = (url: string, params: ParamsStore) =>
  url.includes('?')
    ? urlUtils.appendParams(url, paramsUtils.getPersistentUrlParams(params))
    : urlUtils.buildInternal(url, params)

const InternalLink = ({ children, className, onClick, to }: LinkProps) => {
  const [params] = useParams()
  const url = useMemo(() => adjustLinkParams(to, params), [params, to])

  return (
    <Link className={className} to={url} onClick={e => onClick?.(e, url)}>
      {children}
    </Link>
  )
}

const ExternalLink = ({ children, className, onClick, to }: LinkProps) => {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" className={className} onClick={e => onClick?.(e, to)}>
      {children}
    </a>
  )
}

const PopupLink = ({ children, className, onClick, to }: LinkProps) => {
  const [params] = useParams()
  const openPopup = (event: React.MouseEvent) => {
    const linkUrl = to.startsWith('http') ? to : location.origin + adjustLinkParams(to, params)
    onClick?.(event, linkUrl)
    window.open(linkUrl, 'popup', 'popup')
    event.preventDefault()
  }

  return (
    <a href={to} target="popup" className={className} onClick={openPopup}>
      {children}
    </a>
  )
}

// This component is made for links which can be configured by the partner
const ConfigurableLink = (props: SmartLinkProps) => {
  /* istanbul ignore next */
  const { to = '/', popup } = props
  const url = useMemo(() => {
    if (!to.startsWith('http')) {
      return to
    }

    const url = new URL(to)
    return url.origin === location.origin ? `${url.pathname}${url.search}` : to
  }, [to])
  const isInternal = useMemo(() => !url.startsWith('http'), [url])

  if (popup) return <PopupLink {...props} to={url} />

  return isInternal ? <InternalLink {...props} to={url} /> : <ExternalLink {...props} to={url} />
}

export default ConfigurableLink
