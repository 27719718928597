import { useQueries } from '@tanstack/react-query'
import { useMemo } from 'react'

import seatsAPI from '@api/seats'
import { ApiError } from '@lib/api'
import seatSelectionUtils, { BuildSeatsProps } from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'

interface UseSeatsQueriesProps {
  connection: Connection | null
  options?: BuildSeatsProps
  enabled?: boolean
}

type Refetch = () => void
interface Meta {
  isLoading: boolean
  error: ApiError | null | undefined
  status: string[]
}

type UseSeatsQueriesHook = [Seat.Data[], Meta, Refetch]

const useSeatsQueries = ({ connection, enabled, options }: UseSeatsQueriesProps): UseSeatsQueriesHook => {
  const [params] = useParams()

  const parts = useMemo(() => {
    if (!connection) return []
    return connection?.offerBundle ? connection?.segments.map((item: Segment) => item) : [connection]
  }, [connection])

  const combinedQuery = useQueries({
    queries: parts.map(value => {
      const parameters = { ...seatSelectionUtils.buildSeatsParams(value, params, options) }

      return {
        queryKey: ['seats', value?.id, options?.fareClass],
        queryFn: () => seatsAPI.load(parameters),
        enabled: !!connection && enabled && Object.values(parameters).every(item => item),
        staleTime: Infinity,
      }
    }),
    combine: results => {
      return {
        data: utils.array.compact(
          results
            .flatMap(({ data }) => data)
            .map((data, index) => data && { ...data, segment: { ...data.segment, index } }),
        ),
        isLoading: results.some(({ isLoading }) => isLoading),
        error: results.find(Boolean)?.error,
        status: results.map(({ status }) => status),
        refetch: () => results.forEach(result => result.refetch()),
      }
    },
  })

  const meta = {
    isLoading: combinedQuery.isLoading,
    status: combinedQuery.status,
    error: combinedQuery.error as ApiError,
  }

  return [combinedQuery.data, meta, combinedQuery.refetch]
}

export default useSeatsQueries
