import cn from 'classnames'
import React, { ReactNode } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'

import '@components/Section/index.scss'

type BackgroundFill = 'header' | 'default' | 'white' | 'transparent' | string
export interface SectionBackground {
  fill: BackgroundFill
}
export type SectionAppearance = 'flat' | 'elevated'

export interface SectionProps {
  background?: SectionBackground
  appearance?: SectionAppearance
  mobileAppearance?: SectionAppearance
  alignVertical?: 'left' | 'center'
  children: ReactNode
  contentClassName?: string
  className?: string
}

const Section = ({
  background,
  children,
  alignVertical = 'left',
  appearance = 'flat',
  mobileAppearance = 'flat',
  contentClassName,
  className,
}: SectionProps) => {
  const isMobile = useIsMobile()
  const { fill } = background ?? {}
  const adjustedAppearance = isMobile ? mobileAppearance : appearance
  const defaultColor = appearance === 'elevated' ? 'white' : 'default'
  const isImageBackground = fill?.startsWith('http')
  const colorClasses = isImageBackground ? {} : { [fill ?? defaultColor]: true }
  const styles = { backgroundImage: isImageBackground ? `url(${fill})` : undefined }

  return (
    <div
      style={adjustedAppearance === 'flat' ? styles : undefined}
      className={cn(
        className,
        bem('page-section', {
          ...colorClasses,
          'image-background': isImageBackground,
          [`vertical-${alignVertical}`]: true,
          [adjustedAppearance]: true,
        }),
      )}
    >
      <div
        style={adjustedAppearance === 'elevated' ? styles : undefined}
        className={cn(contentClassName, { paper: adjustedAppearance === 'elevated' }, bem('page-section', 'content'))}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
