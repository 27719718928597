import React, { ReactElement, useMemo } from 'react'

import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { Icon } from '@ui'

interface FrequencyProps {
  connections: Connection[]
}

const Frequency = ({ connections }: FrequencyProps): ReactElement => {
  const { t } = useTranslation()
  const { getFrequency, formatTime, parse } = dateUtils

  const sortedDepartureTime = useMemo(
    () =>
      connections
        .sort((a, b) => a.departureTime.localeCompare(b.departureTime))
        .map(({ departureTime }) => departureTime),
    [connections],
  )

  const frequency = useMemo(() => getFrequency(sortedDepartureTime), [getFrequency, sortedDepartureTime])

  return (
    <div className="row departure-time-info__frequency">
      <Icon className="mr-1" name="info" size="medium" />
      <span>
        {t('landing.city.bookingDetails.frequency', {
          frequency,
          start: formatTime(parse(sortedDepartureTime[0], 'UTC')),
          end: formatTime(parse(sortedDepartureTime.at(-1) as string, 'UTC')),
        })}
      </span>
    </div>
  )
}

export default Frequency
