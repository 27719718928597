/* istanbul ignore file */
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Money from '@components/Money'
import config from '@config'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

import '@components/Seats/Train/SelectedSeat/index.scss'

interface Props {
  seat: Seat.Selected
  paxIndex: number
  fareClass?: string
  schemeSegment: Seat.Train.Segment
}

const SelectedSeat = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const [{ currency, marketingCarrierCode }] = useParams()
  const { seat, paxIndex, fareClass, schemeSegment } = props
  const isAncillary = config.seatAncillaryAsSelection[marketingCarrierCode!]

  const carLabel = schemeSegment.cars.find(car => car.index === seat.carIndex)?.label

  return (
    <div className="train__selected-seat row cell mb-1 gap-1 wrap">
      <span className="cell-12 mb-1 mb-lg-0 cell-lg-4 bolder bold-lg">
        {t('seats.train.passengerLabel', { index: paxIndex + 1 })}
      </span>
      <span className="cell-5 grow cell-lg-3">{fareClass}</span>
      <span className="row cell">
        <Icon className="mr-1" name="carriage" size="large" />
        <span>{carLabel ?? 1}</span>
      </span>
      <span className="row cell">
        <Icon className="mr-1" name="place" size="large" />
        <span>{seat.label}</span>
      </span>
      {!isAncillary && (
        <span className="row bold cell no-grow">
          <Money fractional={seat.price ?? 0} currency={currency} />
        </span>
      )}
    </div>
  )
}

export default SelectedSeat
