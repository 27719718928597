import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { Notification } from '@ui'

interface MessageProps {
  savedPrice: number
  currentPrice: number
  onClose: () => void
}

const Message = ({ savedPrice, currentPrice, onClose }: MessageProps): ReactElement => {
  const { t } = useTranslation()

  const key = currentPrice > savedPrice ? 'negative' : 'positive'

  return (
    <div className="cell">
      <Notification type="info" size="small" onClose={onClose}>
        {t(`checkout.priceDiscrepancy.${key}.message`)}
      </Notification>
    </div>
  )
}

export default Message
