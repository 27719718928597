import cn from 'classnames'
import React, { ReactElement, useMemo, useState } from 'react'

import ConfigurableLink from '@components/ConfigurableLink'
import Section from '@components/Section'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Button, MediaCarousel } from '@ui'

type Props = PageBlocks.BlockComponentProps<PageBlocks.NavigateBlock>

import '@components/Blocks/Navigate/index.scss'

const NavigateBlock = (props: Props): ReactElement => {
  const [activeSlide, setActiveSlide] = useState(0)
  const { translationKey, image, alignText, buttonUrl, appearance = 'elevated', onBackground, background } = props
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const translations = t<any, PageBlocks.CardTranslation>(`customBlocks.${translationKey}`, {
    returnObjects: true,
    defaultValue: {},
    postProcess: ['parseHtml'],
    parseHtmlOptions: {
      keyPattern: /\.description/,
    },
  })
  const isVerticalAlignment = image?.position === 'top' || image?.position === 'bottom'
  const { title, description, button } = useMemo(
    () => (Array.isArray(translations) ? translations[activeSlide] : translations),
    [activeSlide, translations],
  )

  // ToDo: Extract into "Welcome" block
  const isWelcomeMessage = onBackground && appearance === 'flat'

  return (
    <Section
      appearance={appearance}
      background={isWelcomeMessage ? { fill: 'transparent' } : background}
      contentClassName={cn('navigate-block', bem('custom-blocks__item', { 'omit-offset': isWelcomeMessage }))}
    >
      <div className={cn('column gap-5 items-center', { 'row-lg gap-lg-7': !isVerticalAlignment }, alignText)}>
        {translationKey && (
          <div
            className={cn('navigate-block__text', { 'cell-lg-6': image, 'cell-lg-10': !image || isVerticalAlignment })}
          >
            <div className="column">
              {title && !isWelcomeMessage && <h2 className="cell mb-4">{title}</h2>}
              {title && isWelcomeMessage && <h1 className="cell mb-4">{title}</h1>}
              {description && (
                <div className={cn('body-16 navigate-block__description', { 'mb-6': !!button })}>{description}</div>
              )}
              {button && (
                <div className="cell no-grow navigate-block__button">
                  <ConfigurableLink to={buttonUrl}>
                    <Button fullWidth={isMobile}>{button}</Button>
                  </ConfigurableLink>
                </div>
              )}
            </div>
          </div>
        )}
        {image?.url && (
          <div
            className={cn('cell-lg-6 navigate-block__image grow', image.position, {
              'cell-lg-12': !translationKey || isVerticalAlignment,
            })}
          >
            {Array.isArray(image.url) ? (
              <MediaCarousel media={image.url} onMediaChange={setActiveSlide} />
            ) : (
              <img src={image.url} />
            )}
          </div>
        )}
      </div>
    </Section>
  )
}

export default NavigateBlock
