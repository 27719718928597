import React, { ReactElement, useState } from 'react'

import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { Divider, Icon, Modal, Tabs } from '@ui'

interface TimetableProps {
  connections: SortedConnections | null
  initialType: ConnectionType
}

interface ScheduleProps {
  connections?: Connection[]
  departureStation?: string
  arrivalStation?: string
}

interface Options {
  label: string
  value: ConnectionType
}

const Item = ({ first, second }: { first?: string; second?: string }): ReactElement => (
  <>
    <div className="row items-center cell px-5 py-3">
      <span className="cell-5">{first}</span>
      <Icon className="cell-1 mr-2" name="arrow-right" size="medium" />
      <span className="cell-5">{second}</span>
    </div>
    <Divider />
  </>
)

const Schedule = ({ connections, departureStation, arrivalStation }: ScheduleProps): ReactElement => (
  <>
    <div className="departure-time-info__modal-timetable-header">
      <Item first={departureStation} second={arrivalStation} />
    </div>
    {connections?.map(({ departureTime, arrivalTime, id }) => (
      <Item
        key={id}
        first={dateUtils.formatTime(dateUtils.parse(departureTime, 'UTC'))}
        second={dateUtils.formatTime(dateUtils.parse(arrivalTime, 'UTC'))}
      />
    ))}
  </>
)

const Timetable = ({ connections, initialType }: TimetableProps): ReactElement => {
  const { t } = useTranslation()
  const [type, setType] = useState<ConnectionType>(initialType)
  const [opened, setOpened] = useState(false)

  const departureStation = connections?.outbounds[0]?.departureStation.name
  const arrivalStation = connections?.outbounds[0]?.arrivalStation.name

  const options: Options[] = [
    { label: t('landing.city.bookingDetails.outbound'), value: 'outbound' },
    { label: t('landing.city.bookingDetails.inbound'), value: 'inbound' },
  ]

  return (
    <>
      <div onClick={() => setOpened(true)} className="row items-center departure-time-info__timetable">
        <Icon className="mr-1 body-14 bold" name="time" size="medium" />
        <span>{t('landing.city.bookingDetails.seeTimetable')}</span>
      </div>
      <Modal
        maxWidth="sm"
        className="departure-time-info__modal"
        title={t('landing.city.bookingDetails.timetable')}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className="departure-time-info__modal-timetable">
          {!!connections?.inbounds.length && <Tabs options={options} value={type} onClick={setType} />}
          {type === 'outbound' && (
            <Schedule
              connections={connections?.outbounds}
              departureStation={departureStation}
              arrivalStation={arrivalStation}
            />
          )}
          {type === 'inbound' && (
            <Schedule
              connections={connections?.inbounds}
              departureStation={arrivalStation}
              arrivalStation={departureStation}
            />
          )}
        </div>
      </Modal>
    </>
  )
}

export default Timetable
