import cn from 'classnames'
import { isAfter } from 'date-fns'
import React, { ReactElement, useEffect, useMemo } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import dateUtils from '@lib/date'
import groupConnections from '@lib/groupConnections'
import { ConnectionsLoaderParams, useConnectionsLoader } from '@loaders/connections'
import Cell from '@pages/SearchResult/WeeklyList/Item/Cell'
import Column from '@pages/SearchResult/WeeklyList/Item/Column'
import ConnectionCell from '@pages/SearchResult/WeeklyList/Item/ConnectionCell'
import CopyPaste from '@pages/SearchResult/WeeklyList/Item/CopyPaste'

import '@pages/SearchResult/WeeklyList/Item/index.scss'

interface ItemProps {
  connectionParams: ConnectionsLoaderParams
  values: (Connection | null)[]
  onChange: (connections: Record<string, (Connection | null)[]>) => void
  onClick: (data: (Connection | null)[]) => void
  onLoading: (state: Record<string, boolean>) => void
  lastSelected: (Connection | null)[]
  returns: boolean
}

const formatDate = (date: string): string => `${date}T00:00`
const getTime = (date: string): string => dateUtils.formatTime(dateUtils.parse(date, 'UTC'))

const findByTime = (connections: Connection[] | undefined, date: string | null): Connection | null =>
  connections?.find(({ departureTime }) => getTime(departureTime) === date) ?? null

const Item = (props: ItemProps): ReactElement => {
  const isMobile = useIsMobile()
  const { connectionParams, values, onChange, returns, lastSelected, onClick, onLoading } = props
  const { departureDate } = connectionParams
  const { format, parse } = dateUtils

  const [outbound, inbound] = values ?? []

  const { data, isLoading, error } = useConnectionsLoader(connectionParams, {
    onSuccess: data => {
      const list = values?.map(connection => connection?.id) ?? [null, null]
      const filtered = list?.map(item => data.find(({ id }) => id === item) ?? null)

      onChange({ [departureDate]: filtered })
    },
  })

  useEffect(() => {
    onLoading({ [departureDate]: isLoading })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const connections = useMemo(() => data && groupConnections(data, connectionParams), [connectionParams, data])

  const disabled = returns ? !outbound || !inbound : !outbound
  const isTimeCopied = !!lastSelected.filter(Boolean).length

  const getConnections = (selected: Connection | null): (Connection | null)[] => {
    if (!selected || !inbound) return [selected, inbound]

    const outboundDepartureTime = new Date(selected.departureTime)
    const outboundArrivalTime = new Date(selected.arrivalTime)
    const inboundDepartureTime = new Date(inbound.departureTime)

    if (isAfter(outboundArrivalTime, inboundDepartureTime) || isAfter(outboundDepartureTime, inboundDepartureTime))
      return [selected, null]

    return [selected, inbound]
  }

  const handleChange = (selected: Connection | null, type: ConnectionType): void => {
    const data = type === 'outbound' ? getConnections(selected) : [outbound, selected]

    onChange({ [departureDate]: data })
  }

  const handlePasteTime = (): void => {
    const [outbound, inbound] = lastSelected
    const outboundTime = outbound && getTime(outbound.departureTime)
    const inboundTime = inbound && getTime(inbound.departureTime)

    const foundOutbound = findByTime(connections?.outbounds, outboundTime)
    const foundInbound = findByTime(connections?.inbounds, inboundTime)

    onChange({ [departureDate]: [foundOutbound, foundInbound] })
  }

  return (
    <Column className="weekly-list__item" returns>
      <Cell className="weekly-list__date" header={!isMobile}>
        {format(parse(formatDate(departureDate), 'UTC'), 'E, d MMM')}
      </Cell>
      <Cell className="weekly-list__departure-time pr-lg-1">
        <ConnectionCell
          value={outbound}
          connections={connections?.outbounds}
          isLoading={isLoading}
          error={error}
          onChange={selected => handleChange(selected, 'outbound')}
        />
      </Cell>
      {returns && (
        <Cell className="weekly-list__return-time pr-lg-1">
          <ConnectionCell
            value={inbound}
            connections={connections?.inbounds}
            target={outbound}
            isLoading={isLoading}
            error={error}
            onChange={selected => handleChange(selected, 'inbound')}
          />
        </Cell>
      )}
      <Cell className={cn({ end: isMobile })} header={!isMobile} removeBorders>
        <CopyPaste
          disabled={disabled || isLoading}
          isTimeCopied={isTimeCopied}
          onCopy={() => onClick([outbound, inbound])}
          onPaste={handlePasteTime}
        />
      </Cell>
    </Column>
  )
}

export default Item
