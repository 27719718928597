import { useMemo } from 'react'

import { UpdatedFareClass } from '@pages/Checkout/FareClassesSelection'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

interface UseFareClassResult {
  showFareClassSelection: boolean
  showFareClassSplit: boolean
  fareClassList: UpdatedFareClass[]
}

const useFareClass = (): UseFareClassResult => {
  const [{ outbound, inbound }] = useCheckout()
  const [{ fareClasses }] = useSettings()
  const { displayOn, split, supported } = fareClasses

  const fareClassList = useMemo(() => {
    const fares = [outbound?.fares ?? [], inbound?.fares ?? []].flat()
    const classes = fares.reduce<UpdatedFareClass[]>((mem, { fareClass, price }) => {
      const existedFare = mem.find(({ code }) => code === fareClass.code)

      if (!existedFare) return [...mem, { ...fareClass, price }]

      existedFare.price = { ...existedFare.price, fractional: existedFare.price.fractional + price.fractional }

      return mem
    }, [])

    return classes.filter(({ code }) => supported.length === 0 || supported.includes(code))
  }, [outbound?.fares, inbound?.fares, supported])

  const isFareClassSelection = displayOn === 'checkout' || displayOn === 'everywhere'
  const showFareClassSelection = (isFareClassSelection && !split && fareClassList.length > 1) || !outbound

  return {
    showFareClassSelection,
    fareClassList,
    showFareClassSplit: isFareClassSelection && split,
  }
}

export default useFareClass
