import { PaymentAction } from '@adyen/adyen-web/types'

import { BookingPassenger } from '@api/booking'
import makeApi from '@lib/api'

interface RequestBody {
  paymentMethod: PaymentMethodType | null
  paymentMethodData?: object
  paymentProvider?: string | null
  cpf?: string | null
  cardData?: object
  cardHolderName?: string | null
  description?: string | null
  browserInfo?: object
  streetAndNumber?: string | null
  zipCode?: string | null
  countryCode?: string | null
  city?: string | null
  state?: string | null
  deviceFingerprint?: string | null
  passengers: BookingPassenger[]
  bookingFormId: number
  termsAccepted: boolean
}

export interface Response {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  reservationStatus: string
  details: {
    action: PaymentAction
  }
  reservationId: number | null
}

export interface Request extends RequestBody {
  id: string
}

export const pay = async (params: Request): Promise<Response> => {
  const { id, ...body } = params

  const url = `/reservations/${id}/pay`
  const callApi = makeApi.post<{}, RequestBody, Response>({
    type: 'reservations',
    old: url,
    new: url,
  })

  return await callApi({ body, params: {} })
}
