import connectionsAPI, { ConnectionsRequest, ConnectionsResponse } from '@api/connections'
import date from '@lib/date'
import { makeLoader, LoaderFunction } from '@lib/loader'
import paramsUtils from '@lib/params'
import utils from '@lib/utils'
import settingsStore from '@stores/settings'

export interface PassengersTypeParams {
  pax: number
  maxAge: number
  cards?: DiscountCode.Card[]
  firstName?: string
  lastName?: string
}

export interface ConnectionsLoaderParams {
  arrivalLocation: Location.Item | null
  departureLocation: Location.Item | null
  retailerPartnerNumber: number
  pax: number
  locale: Locale
  currency: Currency
  marketingCarrierCode: string | null
  passengers?: PassengersTypeParams[] | null
  cards?: DiscountCode.Card[] | null
  bookingId?: string | null
  returnDate?: string | null
  departureEndTime?: string | null
  departureDate: string
  departureTime: string | null
  twoStepReturnSearch?: boolean | null
  offerId?: string | null
  seatProduct?: boolean
}

// istanbul ignore next
export const loadConnections: LoaderFunction<ConnectionsLoaderParams, ConnectionsResponse> = async params => {
  const { tripTime } = settingsStore.get()

  const departureDate = date.parse(params.departureDate)
  const departureStartTime =
    params.departureTime ?? paramsUtils.getDefaultDepartureTime(tripTime.enabled, departureDate)

  const filteredParams = utils.object.compact<ConnectionsRequest>({
    ...(params.departureLocation && paramsUtils.flatLocation(params.departureLocation, 'departure')),
    ...(params.arrivalLocation && paramsUtils.flatLocation(params.arrivalLocation, 'arrival')),
    pax: params.pax,
    locale: params.locale,
    currency: params.currency,
    marketingCarrierCode: params.marketingCarrierCode,
    retailerPartnerNumber: params.retailerPartnerNumber,
    passengers: params.passengers?.filter(p => p.pax > 0),
    cards: params.cards,
    bookingId: params.bookingId,
    returnDate: params.returnDate,
    departureDate: params.departureDate,
    departureStartTime,
    departureEndTime: params.departureEndTime,
    twoStepReturnSearch: params.twoStepReturnSearch,
    offerId: params.offerId,
    productTypes: params.seatProduct ? ['seat'] : undefined,
  })

  return await connectionsAPI.load(filteredParams)
}

export const useConnectionsLoader = makeLoader<ConnectionsLoaderParams, ConnectionsResponse>({
  key: 'connections',
  keepPreviousData: false,
  cacheTime: 2 * 60 * 1000,
  staleTime: Infinity,
  enabled: ({ departureLocation, arrivalLocation }) => departureLocation != null && arrivalLocation != null,
  loader: loadConnections,
})
