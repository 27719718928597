import { useFormikContext } from 'formik'
import React, { Fragment, ReactElement, useMemo, useState } from 'react'

import amplitude from '@lib/analytics/amplitude'
import fare from '@lib/fare'
import fareClassUtils from '@lib/fareClass'
import sorting from '@lib/sorting'
import FareClassItem from '@pages/Checkout/FareClassesSelection/FareClassItem'
import FareClassSkeleton from '@pages/Checkout/FareClassesSelection/FareClassSkeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { UpdatedMediaData } from '@queries/media'
import { Modal, Skeleton } from '@ui'

export interface UpdatedFareClass extends FareClass {
  price: Money
}

interface FareClassesSelectionProps {
  fareClassList: UpdatedFareClass[]
  isLoading?: boolean
  media?: UpdatedMediaData[]
}

const FareClassesSelection = ({ fareClassList, isLoading, media }: FareClassesSelectionProps): ReactElement => {
  const [selectedFareClass, setSelectedFareClass] = useState<UpdatedFareClass | null>(null)
  const {
    values: { fareClass: selectedClass },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const commonFareFeatures = useMemo(() => fare.getCommonFareFeatureCodes(fareClassList), [fareClassList])
  const selectFareClass = ({ code }: FareClass): void => {
    setFieldValue('fareClass', code)
    setFieldValue('seats', null)
    amplitude.checkout.changeFareClass({ fromFareClass: selectedClass, toFareClass: code })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sortedFareClassList = useMemo(() => sorting.sortBy(fareClassList, 'code', selectedClass), [fareClassList])

  const handleModalOpen = (e: React.MouseEvent<HTMLDivElement>, fareClass: UpdatedFareClass): void => {
    e.stopPropagation()
    setSelectedFareClass(fareClass)
  }

  return (
    <div className="fare-class__container row gap-2 items-stretch">
      <Skeleton.List Skeleton={FareClassSkeleton} loading={!!isLoading} amount={2}>
        <>
          {sortedFareClassList.map(fareClass => (
            <div className="cell-11 cell-lg-6" key={fareClass.code}>
              <FareClassItem
                excludeFeatures={commonFareFeatures}
                fareClass={fareClass}
                selected={selectedClass === fareClass.code}
                onClick={() => selectFareClass(fareClass)}
                onModalOpen={e => handleModalOpen(e, fareClass)}
                media={media?.flatMap(item => fareClassUtils.getMedia(item, fareClass.code))}
              />
            </div>
          ))}
        </>
      </Skeleton.List>
      <Modal
        className="fare-class__modal"
        title={selectedFareClass?.name}
        opened={!!selectedFareClass}
        onClose={() => setSelectedFareClass(null)}
        closeAfterTransition
      >
        <div className="column gap-2">
          {selectedFareClass?.fareFeatures.map(item => (
            <div key={item.id}>
              <span className="bold">{`${item.name}: `}</span>
              <span>{item.description}</span>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default FareClassesSelection
