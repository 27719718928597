import { useMemo } from 'react'

import { buildDelayedMethod } from '@pages/Checkout/hooks/Payment/Delayed/helpers/build'
import useCash from '@pages/Checkout/hooks/Payment/Delayed/useCash'

const invoice = buildDelayedMethod({ type: 'invoice' })

export const useDelayedMethods = () => {
  const cash = useCash()

  return useMemo(() => ({ cash, invoice }), [cash])
}
