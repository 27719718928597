import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import Amenity from '@components/JourneyCard/Footer/Amenity'
import AlternativeDiscountModal from '@components/Seats/Bus/AlternativeDiscountModal'
import Deck from '@components/Seats/Bus/Deck'
import SeatsFareClasses from '@components/Seats/Bus/FareClasses'
import Footer from '@components/Seats/Bus/Footer'
import SegmentLabel from '@components/Seats/Bus/SegmentLabel'
import useItemsSelection from '@components/Seats/hooks/useItemsSelection'
import useIsMobile from '@hooks/useIsMobile'
import useLinkedListWithIndex from '@hooks/useLinkedListWithIndex'
import bem from '@lib/bem'
import fareUtils from '@lib/fare'
import { useTranslation } from '@lib/i18n'
import seatSelectionUtils from '@lib/seatSelection'
import utils from '@lib/utils'
import { useDiscounts } from '@queries/dicounts'
import { useParams } from '@stores/params'
import { Modal, Tabs } from '@ui'

import '@components/Seats/Bus/index.scss'

interface SeatSelectionProps {
  opened: boolean
  alwaysShowHeader?: boolean
  layout: Seat.ByTripDirection<Seat.Data[]>
  connections: Seat.ByTripDirection<Connection | null>
  initialSelection?: Seat.ByTripDirection | null
  reservedSeatsCount: number
  onSubmit: (data: Seat.SubmitData) => void
  onClose: () => void
  onClick?: () => void
  fareFilter?: string | null
  initialFare?: string | null
  outboundSeatsCount?: number
  isFullPrice?: boolean
  requiredSeats?: number
  discountDropdown?: boolean
}

const getInitialLevel = (layout: Seat.Data, fareClassCode: string): number => {
  return layout?.cars[0].seats.find(item => item.fareClass === fareClassCode)?.coordinates.z ?? 0
}

const isSeat = (seat: Seat.Entry): boolean => seat.code !== 'WC' && seat.code !== 'ES'
const getSegmentIndex = (data: Seat.Data) => data.segment.index

const SeatSelectionBus = ({
  opened,
  alwaysShowHeader,
  layout,
  connections,
  reservedSeatsCount,
  onSubmit,
  onClose,
  fareFilter,
  initialFare,
  outboundSeatsCount: _outboundSeatsCount,
  isFullPrice,
  requiredSeats,
  discountDropdown,
  initialSelection,
}: SeatSelectionProps): ReactElement => {
  const [fareClassCode, setFareClassCode] = useState<string>('')
  const [deckLevel, setDeckLevel] = useState<number>(0)
  const [{ currency, passengers }] = useParams()

  const selection = useItemsSelection(initialSelection)
  const [direction, setDirection] = useState<ConnectionType>('outbound')
  const connection = connections[direction]
  // ToDo: I'm not sure that segment.index is a required property, so here we have a fallback to the array index
  //  until we confirm the status of this field
  const indexedLayout = useMemo(() => {
    const filteredByFare = fareFilter
      ? seatSelectionUtils.filterLayoutByFareClass(layout[direction], fareFilter)
      : layout[direction]

    return filteredByFare.map(data => ({
      ...data,
      segment: { ...data.segment, index: data.segment.index },
    }))
  }, [fareFilter, layout, direction])

  const segmentList = useLinkedListWithIndex<Seat.Data>(indexedLayout, getSegmentIndex)
  const schemeSegment = segmentList.current.item!
  const selectedSegmentSeats = useMemo(
    () => selection.items[direction][schemeSegment.segment.index] ?? [],
    [direction, schemeSegment.segment.index, selection.items],
  )

  const isFirstPart = direction === 'outbound' && segmentList.current.position === 0
  const outboundSeatsCount =
    (isFirstPart ? null : Object.values(selection.items.outbound)[0]?.length) ?? _outboundSeatsCount ?? 0

  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const { cars, segment } = { ...schemeSegment }
  const maxSegmentCount = segmentList.length
  const seats = useMemo(() => cars?.flatMap(car => car.seats) ?? /* istanbul ignore next */ [], [cars])

  useEffect(() => {
    const fareClassCode = initialFare || /* istanbul ignore next */ seatSelectionUtils.getUniqueFares(schemeSegment)[0]
    const deckLevel = getInitialLevel(schemeSegment, fareClassCode)

    setFareClassCode(fareClassCode)
    setDeckLevel(deckLevel)
  }, [initialFare, opened, schemeSegment])

  useEffect(() => {
    if (fareClassCode) {
      setDeckLevel(getInitialLevel(schemeSegment, fareClassCode))
    }
  }, [fareClassCode, opened, schemeSegment])

  const deckTabs = useMemo(
    () =>
      seatSelectionUtils.getUniqueLevel(schemeSegment, fareClassCode).map(item => ({
        value: item,
        label: item === 0 ? t('seats.lower_deck') : t('seats.upper_deck'),
      })),
    [schemeSegment, fareClassCode, t],
  )

  const filteredSeats = useMemo(() => {
    const levels = cars?.flatMap(item => item.seats.filter(el => el.coordinates.z === deckLevel))

    return fareClassCode
      ? levels?.filter(item => item.fareClass === fareClassCode || !item.fareClass || !isSeat(item))
      : levels
  }, [cars, deckLevel, fareClassCode])

  const amenities = useMemo(() => {
    const fareFeature = fareUtils.getFareByCode(fareClassCode, connection)

    return fareFeature?.fareClass.fareFeatures.map(item => (
      <Amenity key={item.id} fareFeature={item} tooltipPosition="bottom" />
    ))
  }, [connection, fareClassCode])

  const changeFareClass = (fareClassCode: string): void => {
    setFareClassCode(fareClassCode)
    selection.clear()
    segmentList.reset()
    setDirection('outbound')
  }

  const header =
    alwaysShowHeader || segmentList.length > 1 ? (
      <SegmentLabel
        segment={segment}
        connection={connection}
        onNavigateBack={() => segmentList.move(-1)}
        segmentsCount={segmentList.length}
        currentIndex={schemeSegment.segment.index}
        maxSegmentCount={maxSegmentCount}
      />
    ) : null

  const discounts = useDiscounts({ enabled: !!discountDropdown })
  const priceCategories = seatSelectionUtils.getPriceCategories(seats)
  const discountCategories = useMemo(() => seatSelectionUtils.getDiscountCategories(cars), [cars])

  const firstPassengerCard = passengers?.[0]?.cards?.[0]?.name ?? null
  const passengerDiscountEnabled = discountCategories.length > 0 && !!discountDropdown

  const [passengerCard, setPassengerCard] = useState<string | null>(firstPassengerCard)

  const isFinalStep = !segmentList.current.next?.item && (!layout.inbound.length || direction === 'inbound')
  const nextStep = () => {
    if (segmentList.current.next?.item) {
      segmentList.move(1)
    } else {
      setDirection('inbound')
    }
  }
  /* istanbul ignore next */
  const submit = () => {
    if (!isFinalStep) return nextStep()

    const getPassengerDiscount = () => {
      if (!passengerDiscountEnabled) return undefined
      if (!passengerCard) return null
      return utils.array.findBy(discounts.data ?? [], 'name', passengerCard)
    }
    onSubmit({ seats: selection.items, passengerCard: getPassengerDiscount() })
  }

  const [altDiscountOpened, setAltDiscountOpened] = useState<boolean>(false)
  useEffect(() => {
    if (opened) {
      const isOpened = firstPassengerCard != null && seatSelectionUtils.isLimitedSeatsTaken(seats, firstPassengerCard)
      setAltDiscountOpened(isOpened)
    }
  }, [firstPassengerCard, opened, seats])

  const startX = useMemo(() => Math.min(...seats.map(({ coordinates }) => coordinates.x)), [seats])
  const deckSize = useMemo(
    () => ({
      length: Math.max(Math.max(...seats.map(({ coordinates }) => coordinates.x)) + (isMobile ? 3 : 2) - startX, 12),
      width: 5,
      height: 1,
    }),
    [isMobile, seats, startX],
  )

  const sumPrice = useMemo(() => {
    if (selectedSegmentSeats.some(seat => !!seat.limitations?.length)) return null

    const fractional = utils.array.sum(selectedSegmentSeats, ({ price, fareClass }) => {
      const fare = isFullPrice ? connection?.fares?.find(el => el.fareClass.code === fareClass) : null
      const farePrice: number = fare?.price?.fractional ?? /* istanbul ignore next */ 0

      if (requiredSeats != null) return Number(price) + farePrice / requiredSeats

      return Number(price) + farePrice
    })

    return { fractional, currency }
  }, [connection, currency, isFullPrice, requiredSeats, selectedSegmentSeats])

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeAfterTransition
      title={t('seats.title')}
      header={header}
      fullScreen={isMobile}
      className={bem('seat-selection', 'modal')}
      footer={
        <Footer
          requiredSeats={requiredSeats}
          reservedSeatsCount={reservedSeatsCount}
          passengerCount={selectedSegmentSeats.length}
          outboundSeatsCount={outboundSeatsCount}
          onClick={submit}
          price={sumPrice}
        />
      }
    >
      <>
        <div className="seat-selection__tabs column row-lg space-between">
          <SeatsFareClasses
            connection={connection}
            value={fareClassCode}
            onChange={changeFareClass}
            segmentLayout={schemeSegment}
          />
          {deckTabs.length > 1 && (
            <div className={bem('seat-selection', 'tabs-level')}>
              <Tabs options={deckTabs} value={deckLevel} variant="outlined" onClick={setDeckLevel} />
            </div>
          )}
        </div>
        <Deck
          startX={startX}
          deckSize={deckSize}
          seats={filteredSeats}
          priceCategories={priceCategories}
          discountCategories={discountCategories}
          onClick={seat => selection.toggle(seat, direction, schemeSegment.segment.index)}
          selectedSeats={selectedSegmentSeats}
          amenities={amenities}
          passengerDiscountEnabled={passengerDiscountEnabled}
          passengerCard={passengerCard}
          discounts={discounts.data}
          setPassengerCard={setPassengerCard}
        />
      </>
      <AlternativeDiscountModal
        opened={altDiscountOpened}
        selectedDiscount={passengerCard}
        setSelectedDiscount={setPassengerCard}
        onClose={() => setAltDiscountOpened(false)}
        onCloseTrip={onClose}
        discounts={discounts.data}
      />
    </Modal>
  )
}

export default SeatSelectionBus
