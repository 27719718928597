import React, { ReactElement } from 'react'

import Frequency from '@components/TripBreakdown/DepartureTimeSchedule/Frequency'
import Timetable from '@components/TripBreakdown/DepartureTimeSchedule/Timetable'

import '@components/TripBreakdown/DepartureTimeSchedule/index.scss'

interface DepartureTimeInfoProps {
  initialType: 'outbound' | 'inbound'
  list: SortedConnections | null
  connections: Connection[]
}

const DepartureTimeSchedule = ({ connections, list, initialType }: DepartureTimeInfoProps): ReactElement => (
  <div className="departure-time-info column mt-3">
    <Frequency connections={connections} />
    <Timetable initialType={initialType} connections={list} />
  </div>
)

export default DepartureTimeSchedule
