import { addDays, getUnixTime, isAfter, isBefore, isFuture, isSameDay, isToday, parseISO, subDays } from 'date-fns'
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { CheapestPricesResponse } from '@api/connections/cheapestPrices'
import CheapestPricesLoader from '@components/ConnectionCalendar/Loaders/cheapestPrices'
import ErrorBoundary from '@components/ErrorBoundary'
import useConnectionsSearchParams from '@components/JourneyList/hooks/useConnectionsSearchParams'
import Money from '@components/Money'
import useSearchParams from '@hooks/useSearchParams'
import amplitude from '@lib/analytics/amplitude'
import dateUtils from '@lib/date'
import { CheapestPricesParams } from '@loaders/connections/cheapestPrices'
import { useSettings } from '@queries/settings'
import { useConnectionCalendar } from '@stores/connectionCalendar'
import { useParams } from '@stores/params'
import Carousel, { CarouselNavigationDirection } from '@ui/Carousel'
import Item, { CarouselItemConfig } from '@ui/Carousel/Item'

import '@components/ConnectionCalendar/index.scss'

const DATE_FORMAT: Record<string, string> = {
  'pt-BR': 'EEEEEE, d MMM',
  default: 'E, d MMM',
}

const NO_PRICE_VALUE = '-'
const CAROUSEL_VIEWPORT_LENGTH = 3

type ItemConfiguration = CarouselItemConfig

interface AvailableDatesList {
  outbound: Date[]
  inbound: Date[]
}

const getInitialAvailableDates = (searchDate: Date): Date[] =>
  isFuture(searchDate)
    ? [subDays(searchDate, 1), searchDate, addDays(searchDate, 1)]
    : [searchDate, addDays(searchDate, 1), addDays(searchDate, 2)]

const getDisplayPrice = (value: Money | null): ReactNode => {
  if (!value) return NO_PRICE_VALUE

  /* istanbul ignore next */
  const fallback = () => <>NO_PRICE_VALUE</>

  return (
    <ErrorBoundary fallback={fallback}>
      <Money {...value} />
    </ErrorBoundary>
  )
}

interface ConnectionCalendarProps {
  outbound?: Connection | null
  isLoading: boolean
}

const ConnectionCalendar = (props: ConnectionCalendarProps): ReactElement => {
  const { outbound, isLoading } = props
  const type = outbound ? 'inbound' : 'outbound'
  const [searchParams] = useParams()
  const [queryParams, setQueryParams] = useSearchParams()
  const [{ searchCalendar }] = useSettings()
  const { displayPrice } = searchCalendar
  const [{ selectedDate, outboundDate, inboundDate, calendar, cheapestPrices }, setCalendar] = useConnectionCalendar()

  const isOutbound = useMemo(() => type === 'outbound', [type])
  const searchDate = useMemo(() => {
    const date = !isOutbound && searchParams.returnDate ? searchParams.returnDate : searchParams.departureDate

    return dateUtils.parse(date)
  }, [isOutbound, searchParams.departureDate, searchParams.returnDate])
  const dateFormat = useMemo(() => DATE_FORMAT[searchParams.locale] ?? DATE_FORMAT.default, [searchParams.locale])

  const [pricesLoading, setPricesLoading] = useState<string[]>([])
  const [availableDates, setAvailableDates] = useState<AvailableDatesList>({
    outbound: getInitialAvailableDates(searchDate),
    inbound: [],
  })

  useEffect(() => {
    !selectedDate && setCalendar({ selectedDate: searchDate })
  }, [searchDate, selectedDate, setCalendar, calendar])

  useEffect(() => {
    setAvailableDates(prevState => ({ ...prevState, [type]: getInitialAvailableDates(searchDate) }))
    setCalendar({ selectedDate: searchDate })
  }, [searchDate, setCalendar, type])

  const itemsConfig = useMemo(() => {
    return availableDates[type]
      .map(date => {
        return {
          id: date,
          isSelected: isSameDay(date, selectedDate as Date),
          isLoading: pricesLoading.includes(dateUtils.formatDate(date)) || isLoading,
          title: (date: Date) => dateUtils.format(date, dateFormat),
          description: (date: Date) => getDisplayPrice(cheapestPrices[dateUtils.formatDate(date)]?.[type]),
        }
      })
      .sort((first, second) => getUnixTime(first.id) - getUnixTime(second.id))
  }, [availableDates, type, selectedDate, pricesLoading, isLoading, cheapestPrices, dateFormat])

  const selectedItemIndex = useMemo(
    () => Math.max(itemsConfig.findIndex(configItem => isSameDay(configItem.id, selectedDate as Date), 0)),
    [selectedDate, itemsConfig],
  )

  const canMoveForward = (direction: CarouselNavigationDirection, date: Date): boolean =>
    direction === 'forward' && isSameDay(availableDates[type][availableDates[type].length - 1], date)

  const canMoveBack = (direction: CarouselNavigationDirection, date: Date): boolean => {
    const newDate = subDays(date, 1)
    const returnDepartureDate = outbound && dateUtils.formatDate(new Date(outbound.departureTime))

    if (returnDepartureDate) {
      return !isBefore(newDate, dateUtils.parse(returnDepartureDate))
    }

    return direction === 'back' && isSameDay(availableDates[type][0], date) && (isFuture(newDate) || isToday(newDate))
  }

  const onDateChange = ({ id: date }: ItemConfiguration, direction: CarouselNavigationDirection): void => {
    const currentDate = date as Date
    const formattedDate = dateUtils.formatDate(currentDate)

    /* istanbul ignore next */
    if (isSameDay(currentDate, selectedDate as Date)) return

    amplitude.results.clickCalendar(currentDate, selectedDate as Date)

    setCalendar({
      selectedDate: currentDate,
      outboundDate: isOutbound ? currentDate : outboundDate,
      inboundDate: isOutbound ? inboundDate : currentDate,
    })

    const params = isOutbound ? { departureDate: formattedDate } : { returnDate: formattedDate }
    setQueryParams({ ...queryParams, ...params })

    if (canMoveForward(direction, currentDate)) {
      setAvailableDates({ ...availableDates, [type]: [...availableDates[type], addDays(currentDate, 1)] })
    } else if (canMoveBack(direction, currentDate)) {
      const date = subDays(currentDate, 1)
      if (availableDates[type].some(item => item.toString() === date.toString())) return

      setAvailableDates({ ...availableDates, [type]: [date, ...availableDates[type]] })
    }
  }

  const isDateValid = useCallback(
    (date: Date): boolean => {
      if (!searchParams.returnDate) return true

      if (!outbound) {
        return !isAfter(date, parseISO(searchParams.returnDate))
      } else {
        return isAfter(date, parseISO(outbound.arrivalTime)) || isSameDay(date, parseISO(outbound.arrivalTime))
      }
    },
    [outbound, searchParams.returnDate],
  )
  const connectionsParams = useConnectionsSearchParams()

  const cheapestPricesParams = useMemo(() => {
    const { departureLocation, arrivalLocation, departureDate } = connectionsParams

    const isPriceAbsent = (date: Date): boolean =>
      isDateValid(date) &&
      (!Object.keys(cheapestPrices).includes(dateUtils.formatDate(date)) ||
        cheapestPrices[dateUtils.formatDate(date)][type] == null)

    const locations = isOutbound ? {} : { departureLocation: departureLocation, arrivalLocation: arrivalLocation }

    const departureDates =
      displayPrice === 'today' ? [departureDate] : availableDates[type].filter(isPriceAbsent).map(dateUtils.formatDate)

    return { ...searchParams, ...locations, departureDates, passengers: connectionsParams.passengers }
  }, [connectionsParams, isOutbound, displayPrice, availableDates, type, searchParams, isDateValid, cheapestPrices])

  const onPricesLoadingChange = useCallback((loading: boolean, params: CheapestPricesParams) => {
    setPricesLoading(loading ? params.departureDates : [])
  }, [])

  const onPricesLoad = useCallback(
    (data: CheapestPricesResponse) => {
      for (const [date, value] of Object.entries(data)) {
        setCalendar(current => ({
          ...current,
          cheapestPrices: {
            ...current.cheapestPrices,
            [date]: {
              ...current.cheapestPrices[date],
              [type]: value,
            },
          },
        }))
      }
    },
    [setCalendar, type],
  )

  const renderItem = (itemConfig: ItemConfiguration, isSelected: boolean, onChange: () => void): ReactNode => (
    <Item key={itemConfig.id.toString()} itemConfig={itemConfig} isSelected={isSelected} onChange={onChange} />
  )

  return (
    <div className="connection-calendar">
      {!isLoading && (
        <CheapestPricesLoader
          params={cheapestPricesParams}
          onLoading={onPricesLoadingChange}
          onSuccess={onPricesLoad}
        />
      )}
      <Carousel
        renderItem={renderItem}
        itemsConfig={itemsConfig}
        selectedItemIndex={selectedItemIndex}
        viewportLength={CAROUSEL_VIEWPORT_LENGTH}
        onChange={onDateChange}
      />
    </div>
  )
}

export default ConnectionCalendar
