import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import amplitude from '@lib/analytics/amplitude'
import Price from '@pages/Checkout/BookingDetails/Price'
import DefaultPayButton from '@pages/Checkout/BookingDetails/SubmitSection/PayButton/Default'
import Voucher from '@pages/Checkout/BookingDetails/Voucher'
import PriceHints from '@pages/Checkout/Form/PriceHints'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useFinalPrice } from '@pages/Checkout/hooks/usePrice'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'

import '@pages/Checkout/BookingDetails/SubmitSection/index.scss'

interface Props {
  moneySaved?: number
}

const SubmitSection = ({ moneySaved }: Props): ReactElement => {
  const [{ paymentInstance }] = useCheckout()
  const [{ discountVoucher, reservation }] = useSettings()
  const {
    values: { isReservationLoading, isVacancyLoading },
    isSubmitting,
    isValid,
    errors,
  } = useFormikContext<CheckoutFormData>()
  const isPriceLoading = isReservationLoading || isVacancyLoading
  const price = useFinalPrice()
  const showPayButton = !isPriceLoading && price && price.fractional > 0
  const showConfirmButton = !isPriceLoading && price && price.fractional <= 0

  const handleAmplitudeEvent = (): void => {
    amplitude.checkout.clickPay(isValid, errors)
  }

  return (
    <div className="submit-section">
      {discountVoucher.enabled && reservation.enabled && (
        <div className="mb-3">
          <Voucher />
        </div>
      )}
      <Price price={price} />
      {moneySaved && <PriceHints amount={moneySaved} type="absolute" />}
      <div data-tag="pay" className="mt-5" onClickCapture={handleAmplitudeEvent}>
        {isPriceLoading && <DefaultPayButton disabled />}
        {showPayButton && (paymentInstance?.getPayButton?.() ?? <DefaultPayButton disabled={isSubmitting} />)}
        {showConfirmButton && <DefaultPayButton confirm />}
      </div>
    </div>
  )
}

export default SubmitSection
