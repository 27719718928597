import { useMemo } from 'react'

import useConnectionsSearchParams from '@components/JourneyList/hooks/useConnectionsSearchParams'
import dateUtils from '@lib/date'
import groupConnections from '@lib/groupConnections'
import { useConnectionsLoader } from '@loaders/connections'
import { useParams } from '@stores/params'

interface Props {
  connections?: SortedConnections | null
  enabled?: boolean
}

const useTimetable = ({ connections, enabled }: Props): SortedConnections | null => {
  const [params] = useParams()

  const connectionsParams = useConnectionsSearchParams()
  const returnDate = useMemo(
    () => connectionsParams.returnDate ?? params.returnDepartureDate,
    [connectionsParams.returnDate, params.returnDepartureDate],
  )
  const isConnectionDisallowed = connections || !enabled

  const { data } = useConnectionsLoader({
    ...connectionsParams,
    returnDate,
    departureLocation: isConnectionDisallowed ? null : connectionsParams.departureLocation,
    departureTime: dateUtils.startOfDay(),
  })

  const sortedConnections = useMemo(
    () =>
      data &&
      groupConnections(data, {
        ...params,
        returnDate,
      }),
    [data, params, returnDate],
  )

  return connections ?? sortedConnections
}

export default useTimetable
