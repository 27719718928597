/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

import { JsonApiResponse } from '@api/jsonApi'
import seatsAPI, { SeatsAvailabilityApi } from '@api/seats'
import { ApiError } from '@lib/api'
import seatSelectionUtils, { BuildSeatsProps } from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'

interface UseSeatsQueriesProps {
  connection: Connection | null
  options?: BuildSeatsProps
  enabled?: boolean
  passengers?: SeatsAvailabilityApi.Passenger[]
}

const groupCoachesBySegment = (data: SeatsAvailabilityApi.Response['data']): Record<string, Set<string>> =>
  data.reduce(
    (segmentMap, seat) => {
      const segment = seat.relationships.segment.data.id
      const coach = seat.relationships.coach.data.id

      if (!segmentMap[segment]) {
        segmentMap[segment] = new Set([coach])
      } else {
        segmentMap[segment].add(coach)
      }

      return segmentMap
    },
    {} as Record<string, Set<string>>,
  )

interface Result extends Seat.Train.Data {
  isLoading: boolean
  error: ApiError | null
  preselectedSeats: Seat.BySegment
}

const useTwoStepSeatsQuery = ({ connection, enabled = true, options, passengers }: UseSeatsQueriesProps): Result => {
  const [params] = useParams()

  const availabilityParams: SeatsAvailabilityApi.Request | null = connection && {
    ...seatSelectionUtils.buildSeatsParams(connection, params, options),
    retailerPartnerNumber: params.retailerPartnerNumber,
    passengers: passengers?.map(({ type, pax }) => ({ type, pax })) ?? [{ type: 'PNOS', pax: 1 }],
  }
  const availability = useQuery<SeatsAvailabilityApi.Response, ApiError>({
    queryKey: ['seats', 'availability', availabilityParams],
    queryFn: () => seatsAPI.availability(availabilityParams!),
    enabled: !!availabilityParams && enabled,
  })
  const jsonApiResponse = useMemo(
    () => availability.data && new JsonApiResponse(availability.data),
    [availability.data],
  )

  const getAvailability = useMemo(() => {
    if (!jsonApiResponse) return () => null

    const seatsMap = utils.array.mapBy(availability.data?.data ?? [], item => {
      return (
        item.attributes.element_id +
        jsonApiResponse.getRelation(item.relationships.segment).attributes.index +
        jsonApiResponse.getRelation(item.relationships.coach).attributes.order_position
      )
    })

    return (id: string): JsonApi.Item<SeatsAvailabilityApi.Item> | null => seatsMap.get(id) ?? null
  }, [availability.data?.data, jsonApiResponse])

  const scheme = useMemo(() => {
    if (!jsonApiResponse || !availability.data) return []

    const segmentCoachMap = groupCoachesBySegment(availability.data.data)
    const res = Object.entries(segmentCoachMap).map<Seat.Train.Segment>(([segment, coaches]) => {
      const segmentData = jsonApiResponse.groupedData.segments[segment]
      const firstCoach = jsonApiResponse.groupedData.coaches[coaches.values().next().value]
      const vehicle = jsonApiResponse.parseRelationships(firstCoach).vehicle
      const cars = jsonApiResponse.parseRelationships(vehicle).coaches.map(coach => {
        const coachData = jsonApiResponse.parseRelationships(coach)

        return {
          index: coachData.order_position,
          layout: coachData.layouts[0].attributes.code,
          label: coachData.label,
        }
      })

      return {
        index: segmentData.attributes.index,
        cars: cars,
      }
    })

    return res
  }, [availability.data, jsonApiResponse])

  const preselectedSeats = useMemo((): Seat.BySegment => {
    if (!jsonApiResponse) return []

    return Object.values(jsonApiResponse?.groupedData.preselected_seat_availabilities ?? []).reduce<Seat.BySegment>(
      (acc, preselectedData) => {
        const availability = jsonApiResponse.parseRelationships(preselectedData).seat_availability
        const { coach, segment } = jsonApiResponse.parseRelationships(availability)

        const seat: Seat.Selected = {
          code: availability?.attributes.code,
          segmentIndex: segment.attributes.index,
          carIndex: coach.attributes.order_position,
          fareClass: null,
          price: availability?.attributes.price,
        }

        return {
          ...acc,
          [segment.attributes.index]: [...(acc[segment.attributes.index] ?? []), seat],
        }
      },
      {},
    )
  }, [jsonApiResponse])

  const layoutQuery = useCallback(
    (layout: string, segment: number, car: number) => ({
      queryKey: ['seats', 'layout', 'twoStep', layout, segment, car],
      enabled: !!layout && enabled,
      staleTime: Infinity,
      cacheTime: Infinity,
      queryFn: async (): Promise<Seat.Train.Layout> => {
        const data = await seatsAPI.layout(layout)

        return {
          ...data,
          seats: data.elements.map<Seat.Entry>(seat => {
            const { label, item, id, position, size } = seat
            const availability = getAvailability(id + segment + car)!

            const orientationMap: Record<Seat.Train.Rotation, Seat.SeatOrientation> = {
              none: 'facingForward',
              half: 'facingBackward',
              three_quarters: 'facingLeft',
              quarter: 'facingRight',
            }

            return {
              id,
              code: item === 'seat' ? availability?.attributes.code : item,
              label,
              fareClass: null,
              vacant:
                availability?.attributes.status === 'available' || availability?.attributes.status === 'preselected',
              price: availability?.attributes.price ?? 0,
              orientation: orientationMap[position.transformation.rotation],
              direction: 'straight',
              coordinates: position.anchor_point,
              flipped: position.transformation.horizontal_flip,
              size,
            }
          }),
        }
      },
    }),
    [getAvailability, enabled],
  )

  return { scheme, layoutQuery, isLoading: availability.isLoading, error: availability.error, preselectedSeats }
}

export default useTwoStepSeatsQuery
