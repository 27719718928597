/* istanbul ignore file */
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface LinkedNode<T> {
  position: number
  item: T | null
  next?: LinkedNode<T>
  prev?: LinkedNode<T>
}

export interface LinkedListController<T> {
  current: LinkedNode<T>
  move: (direction: -1 | 1) => void
  reset: () => void
  length: number
}

const buildLinkedList = <T>(array: T[], getIndex: (item: T) => number): LinkedNode<T> => {
  if (array.length === 0) {
    return { position: 0, item: null }
  }

  const sorted = array.sort((a, b) => getIndex(a) - getIndex(b))

  const start: LinkedNode<T> = { position: 0, item: sorted[0] }
  let current: LinkedNode<T> = start

  for (let i = 1; i < sorted.length; i++) {
    const next: LinkedNode<T> = { position: i, item: sorted[i] }
    current.next = next
    next.prev = current
    current = next
  }

  return start
}

const useLinkedListWithIndex = <T>(array: T[], getIndex: (item: T) => number): LinkedListController<T> => {
  const firstNode = useMemo(() => buildLinkedList(array, getIndex), [array, getIndex])
  const [currentNode, setCurrentNode] = useState(firstNode)

  useEffect(() => {
    setCurrentNode(firstNode)
  }, [firstNode])

  const move = useCallback((direction: -1 | 1) => {
    setCurrentNode(node => (direction === -1 ? node.prev : node.next) || node)
  }, [])

  return useMemo(
    () => ({
      current: currentNode.item ? currentNode : firstNode,
      move,
      reset: () => setCurrentNode(firstNode),
      length: array.length,
    }),
    [currentNode, firstNode, move, array.length],
  )
}

export default useLinkedListWithIndex
