import cn from 'classnames'
import React, { ReactElement } from 'react'

import ConfigurableLink from '@components/ConfigurableLink'
import TransFromHtml from '@components/TransFromHtml'
import { useTranslation } from '@lib/i18n'
import { Button, Icon, MediaCarousel } from '@ui'

import '@components/Blocks/Cards/Card/index.scss'

const Card = ({ image, translations, buttonUrl }: PageBlocks.CardsBlockElement): ReactElement => {
  const { t } = useTranslation()
  const { title, description, button } = t<any, PageBlocks.CardTranslation>(`customBlocks.${translations}`, {
    returnObjects: true,
    defaultValue: {},
  })
  const imageClasses = cn('mb-5', 'block-card__image')

  return (
    <div className="block-card column">
      {image &&
        (Array.isArray(image.url) ? (
          <MediaCarousel media={image.url as string[]} className={imageClasses} />
        ) : (
          <img src={image.url} className={imageClasses} />
        ))}
      {title && <h3 className="cell mb-1">{title}</h3>}
      {description && (
        <div className="body-16 mb-2">
          <TransFromHtml i18nKey={`customBlocks.${translations}.description`} />
        </div>
      )}
      {button && buttonUrl && (
        <div className="cell no-grow items-center">
          <ConfigurableLink to={buttonUrl}>
            <Button fullWidth={false} variant="text" color="secondary">
              <div className="row items-center">
                <div>{button}</div>
                <Icon name="chevron-right" size="medium" />
              </div>
            </Button>
          </ConfigurableLink>
        </div>
      )}
    </div>
  )
}

export default Card
