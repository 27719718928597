import React from 'react'

import ConfigurableLink from '@components/ConfigurableLink'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'

interface MenuLinkProps {
  url: string
  popup?: boolean
  onLinkClick?: () => void
  translationKey: string
  className?: string
  keyPrefix?: string
}

const MenuLink = (props: MenuLinkProps) => {
  const { url, translationKey, className = 'header-menu__link', keyPrefix = 'header', onLinkClick, popup } = props
  const { t } = useTranslation(undefined)
  const key = `${keyPrefix}.${translationKey}`

  const handleClick = (_: React.MouseEvent, url: string): void => {
    amplitude.common.clickMenuLink(url)
    onLinkClick?.()
  }

  return (
    <ConfigurableLink to={url} className={className} onClick={handleClick} popup={popup}>
      {t(key)}
    </ConfigurableLink>
  )
}

export default MenuLink
