import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import Footer from '@components/Footer'
import Header from '@components/Header'
import { useParams } from '@stores/params'

import '@components/PageWrapper/index.scss'

interface Props {
  children: ReactNode
  className?: string
  banner?: ReactNode
  currencyChangeAllowed?: boolean
}

const PageWrapper = ({ children, className, banner, currencyChangeAllowed }: Props): ReactElement => {
  const [{ mode }] = useParams()

  return (
    <div className={cn('column items-center', className)}>
      <div className="column items-stretch basis-auto page-wrapper__content">
        {banner}
        <div className="cell no-grow mb-lg-2 page-wrapper__header">
          <Header currencyChangeAllowed={currencyChangeAllowed} />
        </div>
        <div className="cell">{children}</div>
      </div>
      {mode !== 'embed' && <Footer />}
    </div>
  )
}

export default PageWrapper
