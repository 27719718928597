import React, { forwardRef, ReactElement, useMemo, useRef, useState } from 'react'

import Connection from '@components/Connection'
import Money from '@components/Money'
import bem from '@lib/bem'
import connectionUtils from '@lib/connection'
import { useTranslation } from '@lib/i18n'
import seatUtils from '@lib/seatSelection'
import { useParams } from '@stores/params'
import { Icon } from '@ui'

import '@components/OutboundTrip/index.scss'

interface OutboundTripProps {
  connection: Connection
  fareClassCode: string
  seats?: Seat.BySegment | null
  onClick: () => void
  isAmendment: boolean
}

const OutboundTrip = ({ connection, onClick, fareClassCode, isAmendment, seats }: OutboundTripProps): ReactElement => {
  const outboundRef = useRef<HTMLDivElement>(null)
  const [opened, setOpened] = useState(true)
  const { t } = useTranslation()
  const [{ currency }] = useParams()

  const fare = connection.fares.find(fare => fare.fareClass.code === fareClassCode) as Fare

  const handleClick = (): void => {
    setOpened(false)

    outboundRef.current?.addEventListener(
      'animationend',
      event => {
        ;(event.target as HTMLElement).style.display = 'none'
        onClick()
      },
      { once: true },
    )
  }

  const price = useMemo(
    () =>
      seats ? seatUtils.sumOutboundPrice(seats, connection, currency) : connectionUtils.getPrice(connection, fare),
    [connection, currency, fare, seats],
  )

  return (
    <div
      ref={outboundRef}
      className={bem('outbound', { opened, closed: !opened })}
      onClick={handleClick}
      data-tag="outbound"
    >
      <div className="outbound__header mb-1">
        <span className={bem('outbound', 'icon')}>
          <Icon name="checkout" size="medium" />
        </span>
        <span className={bem('outbound', 'title')}>{t('journeyList.selectedOutbound')}</span>
      </div>
      <div className={bem('outbound', 'date-range')}>
        <Connection.DepartureDateTime connection={connection} />
        {' - '}
        <Connection.ArrivalDateTime connection={connection} />
      </div>
      <div className="outbound__description row-lg space-between items-center">
        <div className={bem('outbound', 'description-station')}>
          <span className="cell-sm-5">{connection.departureStation.name}</span>
          <Icon name="arrow-right" size="small" />
          <span className="cell-sm-5">{connection.arrivalStation.name}</span>
        </div>
        <div className={bem('outbound', 'description-price')}>
          <Money {...price} signed={isAmendment} />
        </div>
      </div>
    </div>
  )
}

export default forwardRef(OutboundTrip)
